import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Header from '../../../../Components/Header/Header'
import Sidebar from '../../../../Components/Sidebar/Sidebar'
import { useAuthState } from '../../../../Helper/Context'
import { ReportDesignationNameDDLAPI, ReportOfficerNameDDLAPI, SeasonDDLAPI, SeasonWiseMonthDDLAPI } from '../../../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { SeasonDataDDL } from '../../../../Components/CommonDDL/SeasonDataDDL'
import { Loading } from '../../../../Helper/Loading';
import { GetActivityAchivementPDFReportAPI, GetActivityAchivementReportAPI, GetActivityPDFReportAPI } from '../../../../Redux/ReportSlice/ReportSlice';
import { SeasonWiseMonthDataDDL } from '../../../../Components/CommonDDL/SeasonWiseMonthDataDDL';
import { RMOfficerNameDDL } from '../../../../Components/CommonDDL/RMOfficerNameDDL';
import { RMDesignationNameDDL } from '../../../../Components/CommonDDL/RMDesignationNameDDL';
import { toastErrorr } from '../../../../Helper/ToastMessage'

export default function ActivityReport() {
    const userDetails = useAuthState();
    const { UserID, token, EmployeeID } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    const ScreenName = searchParams.get("ScreenName")
    const Flag = searchParams.get("Flag")

    const [IsClear, setIsClear] = useState(false)
    const [Date, setDate] = useState('')
    const [YearValue, setYearValue] = useState(0)
    const [MonthValue, setMonthValue] = useState(0)

    const [SeasonDDL, setSeasonDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [MonthDDL, setMonthDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [ToMonthDDL, setToMonthDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [RMOfficerDDL, setRMOfficerDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [RMDesignationDDL, setRMDesignationDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const handleClear = () => {
        // setIsClear(!IsClear)
        // setDate('')
        setMonthDDL({
            ...MonthDDL,
            ID: 0,
            Label: "Select...",
        })
        setSeasonDDL({
            ...SeasonDDL,
            ID: 0,
            Label: "Select...",
        })
        setToMonthDDL({
            ...ToMonthDDL,
            ID: 0,
            Label: "Select...",
        })
        setRMOfficerDDL({
            ...RMOfficerDDL,
            ID: 0,
            Label: "Select...",
        })
        setRMDesignationDDL({
            ...RMDesignationDDL,
            ID: 0,
            Label: "Select...",
        })
    }


    useEffect(() => {
        const data = {
            UserID,
            token,
            ScreenName: ScreenName,
            Flag: ''
        }
        dispatch(SeasonDDLAPI({ data }))
    }, [Flag])

    const { SeasonDDLData } = useSelector(state => state.SeasonDDLData)

    useEffect(() => {
        const data = { UserID, token, SeasonDDL }
        dispatch(SeasonWiseMonthDDLAPI({ data }))
    }, [SeasonDDL.ID, Flag])

    const { SeasonWiseMonthData } = useSelector(state => state.SeasonWiseMonthDDL)


    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_MonthID: MonthValue,
            From_M_MonthID: MonthDDL.ID,
            To_MonthID: ToMonthDDL.ID,
            M_SeasonID: SeasonDDL.ID,
            M_DesignationID: RMDesignationDDL.ID,
            M_UsersID: UserID,
            Flag: Flag,
            Top: '99999',
            token: token,
        }
        { YearValue && YearValue !== '0' && dispatch(ReportOfficerNameDDLAPI({ data })) }
    }, [Flag, YearValue, MonthValue, MonthDDL.ID, ToMonthDDL.ID, SeasonDDL.ID, RMDesignationDDL.ID])

    const { ReportOfficerNameDDLData } = useSelector(state => state.ReportOfficerNameDDLData)


    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_MonthID: MonthValue,
            From_M_MonthID: MonthDDL.ID,
            To_MonthID: ToMonthDDL.ID,
            M_SeasonID: SeasonDDL.ID,
            M_UsersID: UserID,
            Flag: Flag,
            Top: '99999',
            token: token,
        }
        { YearValue && YearValue !== '0' && dispatch(ReportDesignationNameDDLAPI({ data })) }
    }, [Flag, YearValue, MonthValue, MonthDDL.ID, ToMonthDDL.ID, SeasonDDL.ID])

    const { ReportDesignationNameData } = useSelector(state => state.ReportDesignationNameDDLData)


    useEffect(() => {
        const data = {
            M_MonthID: MonthValue,
            M_UsersID: UserID,
            M_FinancialYearID: YearValue,
            From_M_MonthID: MonthDDL.ID,
            To_MonthID: ToMonthDDL.ID,
            M_SeasonID: SeasonDDL.ID,
            M_DesignationID: RMDesignationDDL.ID,
            M_EmployeeID: RMOfficerDDL.ID,
            token: token,
            Top: '99999',
            Flag: Flag
        }
        { YearValue && YearValue !== '0' && dispatch(GetActivityAchivementReportAPI({ data })) }
    }, [Flag, YearValue, MonthValue, MonthDDL.ID, ToMonthDDL.ID, SeasonDDL.ID, RMDesignationDDL.ID, RMOfficerDDL.ID])

    const { tableData, isLoading } = useSelector(state => state.GetActivityAchivementReportData)

    const GetReport = (PdfUrl) => {
        if (PdfUrl) {
            window.open(PdfUrl)
        } else {
            toastErrorr('Report not found')
        }
    }

    const handleGetReport = (M_UsersID,M_EmployeeID) => {
        const data = {
            M_MonthID: MonthValue,
            M_UsersID: UserID,
            M_FinancialYearID: YearValue,
            From_M_MonthID: MonthDDL.ID,
            To_MonthID: ToMonthDDL.ID,
            M_SeasonID: SeasonDDL.ID,
            M_DesignationID: RMDesignationDDL.ID,
            M_EmployeeID: M_EmployeeID,
            token: token,
            Top: '99999',
            Flag: Flag,
            GetReport: GetReport
        }
        dispatch(GetActivityAchivementPDFReportAPI({ data }))
    }

    const {isReportLoading} = useSelector(state=>state.GetActivityAchivementPDFReportData)


    const handleActivityGetReport = () => {
        const data = {
            M_MonthID: MonthValue,
            M_UsersID: UserID,
            M_FinancialYearID: YearValue,
            From_M_MonthID: MonthDDL.ID,
            To_MonthID: ToMonthDDL.ID,
            M_SeasonID: SeasonDDL.ID,
            M_DesignationID: RMDesignationDDL.ID,
            M_EmployeeID: '0',
            token: token,
            Top: '99999',
            Flag: Flag,
            GetReport: GetReport
        }
        dispatch(GetActivityPDFReportAPI({ data }))
    }

    const {isActivityReportLoading} = useSelector(state=>state.GetActivityPDFReportData)

    return (
        <>
            {isLoading && <Loading />}
            {isReportLoading && <Loading/>}
            {isActivityReportLoading && <Loading/>}
            <main className="main-content position-relative border-radius-lg ">
                <Header setYearValue={setYearValue} setMonthValue={setMonthValue} />
                <Sidebar active={ScreenName} listActive="report" reportsActive="activityreports" />
                <div id="wrapper">
                    <div id="page-content-wrapper">
                        <div className="container-fluid xyz">
                            <div className="row mt_40" >
                                <div className="row mtop">
                                    <div className="col-md-12 grid-margin">
                                        <div className="row page-heading">
                                            <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                                <h4 className="fontStyle">{ScreenName} Activity</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 mb-xl-0">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 shadow table-card mt-1 mx-2">
                                        <div className="filter mb-2 mt-2">
                                            <div className="card-body">
                                                <div className='filter-bg p-2'>
                                                    <div className="row">

                                                        <div className="col-md-6 col-lg-2">
                                                            <SeasonDataDDL
                                                                SeasonDDL={SeasonDDL}
                                                                setSeasonDDL={setSeasonDDL}
                                                                SeasonDDLData={SeasonDDLData}
                                                                mandatory={false}
                                                            />
                                                        </div>

                                                        <div className="col-md-6 col-lg-2">
                                                            <SeasonWiseMonthDataDDL
                                                                MonthDDL={MonthDDL}
                                                                setMonthDDL={setMonthDDL}
                                                                SeasonWiseMonthData={SeasonWiseMonthData}
                                                                mandatory={false}
                                                                name="From Month"
                                                            />
                                                        </div>

                                                        <div className="col-md-6 col-lg-2">
                                                            <SeasonWiseMonthDataDDL
                                                                MonthDDL={ToMonthDDL}
                                                                setMonthDDL={setToMonthDDL}
                                                                SeasonWiseMonthData={SeasonWiseMonthData}
                                                                mandatory={false}
                                                                name="To Month"
                                                            />
                                                        </div>

                                                        <div className="col-md-6 col-lg-3">
                                                            <RMDesignationNameDDL
                                                                RMDesignationDDL={RMDesignationDDL}
                                                                setRMDesignationDDL={setRMDesignationDDL}
                                                                ReportDesignationNameData={ReportDesignationNameData}
                                                                mandatory={false}
                                                            />
                                                        </div>

                                                        <div className="col-md-6 col-lg-3">
                                                            <RMOfficerNameDDL
                                                                RMOfficerDDL={RMOfficerDDL}
                                                                setRMOfficerDDL={setRMOfficerDDL}
                                                                ReportOfficerNameDDLData={ReportOfficerNameDDLData}
                                                                mandatory={false}
                                                            />
                                                        </div>

                                                        <div className="col-12 col-lg-12 clear float-end">

                                                            <button type="button" className="btn addBtn text-white mr-2 mx-2 mt-4 mt-md-0 mt-lg-3 waves-effect waves-light allBtn float-end"
                                                                style={{ width: '130px' }} onClick={()=>handleActivityGetReport()}
                                                            >
                                                                Activity Report
                                                            </button>

                                                            <button type="button" className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-3 waves-effect waves-light allBtn float-end"
                                                                onClick={() => handleClear()}
                                                            >
                                                                Clear
                                                            </button>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive ">
                                            <table id='expenses' cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                                <thead>
                                                    <tr >
                                                        <th>Sr.No.</th>
                                                        <th>Employee Name</th>
                                                        <th>District</th>
                                                        <th>Taluka</th>
                                                        <th>Village</th>
                                                        <th>Report</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        tableData && tableData.table && tableData.table.length > 0 ?
                                                            tableData.table.map((i, j) => {
                                                                return (
                                                                    <tr>
                                                                        <td style={{ width: '8%' }}>{j + 1}</td>
                                                                        <td>{i?.employeeName}</td>
                                                                        <td>{i?.districtName}</td>
                                                                        <td>{i?.talukaName}</td>
                                                                        <td>{i?.villageName}</td>

                                                                        <td style={{ textAlign: 'center' }} onClick={() => handleGetReport(i.m_UserID , i.id)}>
                                                                            <i class="fa-solid fa-file-pdf p-1" title='Report' style={{ fontSize: '1.1rem', color: 'red', cursor: 'pointer' }}
                                                                            ></i>
                                                                        </td>

                                                                    </tr>
                                                                )
                                                            })
                                                            : "No Data Found"
                                                    }


                                                </tbody>
                                            </table>

                                        </div>
                                        {/* {tableData && tableData.table && tableData.table.length > 0 &&
                                            <Pegination
                                                PerPageCount={PerPageCount}
                                                TotalCount={tableData.table[0].totalCount}
                                                setFrom={setFrom}
                                                setTo={setTo}
                                                setrowNo={setrowNo}
                                                CurrentPage={CurrentPage}
                                                setCurrentPage={setCurrentPage}
                                            />
                                        } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main >

        </>
    )
}


