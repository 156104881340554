
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from "moment";

export const LeaveManagementExportData = (props) => {
    const { ExcelData, name, searchName, ApiFlag } = props
    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-FarmerMasterExport"
                className="download-table-xls-button float-end btn btn-export btn-md pt-1 pb-1 pl-3 pr-3 mx-3"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-FarmerMasterExport" style={{ display: 'none', width: '100%' }}>

                <h5><span>&emsp;</span></h5>

                <h4 style={{ textAlign: 'center', fontSize: 16 }}>{name}</h4>

                <tr><td></td></tr>

                <thead className="thead-dark" >
                    <tr style={{ backgroundColor: '#495057', color: "#fff", border: '1px solid' }}>
                        <th>Sr.No.</th>
                        {
                            searchName === "Leave Approved" && ApiFlag === 'Approved' ?
                                <th>Approved Date</th>
                                :
                                <></>
                        }
                        <th>Department</th>
                        <th>Designation</th>
                        <th>Employee Name</th>
                        <th>Leave From Date</th>
                        <th>Leave To Date</th>
                        <th>Total Leave Days </th>
                        <th>Reason </th>
                        {
                            searchName === "Application Received" && ApiFlag === 'AllLeave' ?
                                <th>Status</th>
                                :
                                <></>
                        }

                    </tr>
                </thead>
                <tbody>
                    {
                        ExcelData && ExcelData.table && ExcelData.table.length > 0 ? ExcelData.table.map((item, i) => (
                            <tr style={{ textAlign: "center" }}>
                                <td style={{ textAlign: 'center' }} className='w-5'>{item.rowNum}</td>
                                {
                                    searchName === "Leave Approved" && ApiFlag === 'Approved' ?
                                        <td>{item.statusDate ? item.statusDate : '-'}</td>
                                        :
                                        <></>
                                }
                                <td>{item.departmentName ? item.departmentName : '-'}</td>
                                <td>{item.designationName ? item.designationName : '-'}</td>
                                <td>{item.employeeName ? item.employeeName : '-'}</td>
                                <td>{item.leaveDate ? moment(item.leaveDate).format("DD-MM-YYYY") : "-"}</td>
                                <td>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : "-"}</td>
                                <td>{item.totalLeaveDays ? item.totalLeaveDays : '-'}</td>
                                <td>{item.leaveReason ? item.leaveReason : '-'}</td>

                                {
                                    searchName === "Application Received" && ApiFlag === 'AllLeave' ?
                                        <td>{item.statusName_English ? item.statusName_English : '-'}</td>
                                        :
                                        <></>
                                }
                            </tr>
                        )) : <tr>No data</tr>
                    }
                </tbody>
            </table>
        </div>
    )
}