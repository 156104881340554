
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from "moment";

export const ExportEmployeeWiseAttenanceExcel = (props) => {
    const { ExcelData, name } = props
    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-FarmerMasterExport"
                className="download-table-xls-button float-end btn btn-export btn-md pt-1 pb-1 pl-3 pr-3 mx-3"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />

            <table id="table-to-FarmerMasterExport" style={{ display: 'none', width: '100%' }}>

                <h5><span>&emsp;</span></h5>

                <h4 style={{ textAlign: 'center', fontSize: 16 }}>{name}</h4>
                <div className='row'>
                    <div className="col-md-6 col-lg-3">
                        <div className="form-group">
                            <label className="d-block" htmlFor="NameofDepartment">Employee Name : </label>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-2">
                        <div className="form-group">
                            <label className="d-block" htmlFor="NameofDepartment">Designation :</label>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-2">
                        <div className="form-group">
                            <label className="d-block" htmlFor="NameofDepartment">Department :</label>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-2">
                        <div className="form-group">
                            <label className="d-block" htmlFor="NameofDepartment">Year :</label>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-2">
                        <div className="form-group">
                            <label className="d-block" htmlFor="NameofDepartment">Month :</label>
                        </div>
                    </div>
                </div>

                <tr><td></td></tr>

                <thead className="thead-dark" >
                    <tr style={{ backgroundColor: '#495057', color: "#fff", border: '1px solid' }}>
                        <th style={{ textAlign: "center", width: "10%" }}>Sr.No.</th>
                        <th>Department</th>
                        <th>Designation</th>
                        <th>Employee Name</th>
                        <th>Opening Leave Balance</th>
                        <th>Present Days</th>
                        <th>Absent Days</th>
                        <th>Closing Leave Balance</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        ExcelData && ExcelData.table && ExcelData.table.length > 0 ? ExcelData.table.map((item, i) => (
                            <tr key={i}>
                                <td align='center'>{i + 1}</td>
                                <td>{item?.departmentName}</td>
                                <td>{item?.designationName}</td>
                                <td>{item?.employeeName}</td>
                                <td>{item?.openingLeaveBalance}</td>
                                <td>{item?.totalP_Day}</td>
                                <td>{item?.totalA_Day}</td>
                                <td>{item?.closingLeaveBalance}</td>

                            </tr>
                        )) : <tr>No data</tr>
                    }
                </tbody>
            </table>
        </div>

    )
}