import React, { useState } from 'react'
import { Bar } from 'react-chartjs-2';
import { FinancialOptions, TargetOptions, data, options, targetFinancialOptions, targetFinancialdata } from '../../../../../Helper/Data';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TotalTargetArchievedCard from './TotalTargetArchievedCard';
import './TotalTargetAchievement.css'

export default function TotalTargetAchievement({ Countdata, LableName, activeFilter }) {


    // const [activeFilter, setactiveFilter] = useState("Yearly")
    const [activeClass, setActiveClass] = useState("Target")
    const navigate = useNavigate();

    const targetOrderNavigate = (name, screenFlag) => {
        if (screenFlag === "Target" && name === "Order Target (Nos)") {
            navigate(`/totaltargetorder?name=${name}&activeFilter=${activeFilter}&DDLFlag=DB_OrderTarget&OfficerNameDDLFlag=DB_OrderTarget `) // {state: screenFlag}
            // console.log(activeFilter)
        } else if (screenFlag === "Target" && name === "Order Achievement (Nos)") {
            navigate(`/totaltargetorder?name=${name}&activeFilter=${activeFilter}&DDLFlag=DB_AchievedOrder&StateDDLFlag=DB_AchievedOrder&ExtraAcheivedDDLFlag=DB_ExtraAchivement&OfficerNameDDLFlag=DB_OrderAchivement`)
        } else if (screenFlag === "Financial" && name === "Financial Target (Lacs)") {
            navigate(`/totalfinancialorder?name=${name}&activeFilter=${activeFilter}&DDLFlag=DB_FinancialTarget`)
        } else if (screenFlag === "Financial" && name === "Financial Achievement (Lacs)") {
            navigate(`/totalfinancialorder?name=${name}&activeFilter=${activeFilter}&DDLFlag=DB_AchievedFinancial`)
        }
    }
    // console.log(LableName && LableName.TotalOrderTarget, LableName && LableName.TotalOrderAchivement)
    const Targetdata = {

        // labels: ['Order Target', 'Order Achievement', 'Financial Target', 'Financial Achievement'],
        labels: [LableName && LableName.TotalOrderTarget, LableName && LableName.TotalOrderAchivement],

        datasets: [
            {
                label: '',
                data: [Countdata && Countdata.TotalOrderTarget, Countdata && Countdata.TotalOrderAchivement],
                backgroundColor: [
                    '#e72d36', '#442b6f',
                ],
                // borderWidth: 1,
            },

        ],
    };
    const Financialdata = {
        // labels: ['Order Target', 'Order Achievement', 'Financial Target', 'Financial Achievement'],
        labels: [LableName && LableName.TotalFinancialTarget, LableName && LableName.TotalFinancialAchivement],
        datasets: [
            {
                label: '',
                data: [Countdata && Countdata.TotalFinancialTarget, Countdata && Countdata.TotalFinancialAchivement],
                backgroundColor: [
                    '#e72d36', '#442b6f',
                ],
                // borderWidth: 1,
            },

        ],
    };

    return (
        <>
            <div className="col-9 col-md-10 tta_main_col col-lg-7 mb-lg-0"> 
                <div className="card z-index-2 h-100 pb-5 card_big3" >
                    <div className="card-header pb-0 pt-3 bg-transparent">
                        <div className="row">
                            <div className="col-12 col-lg-12">
                                <h6 className="text-capitalize tta_head material-stock-heading">Total Target & Achievement
                                </h6>
                            </div>

                            {/* <div className="col-12 col-lg-7">
                                <div className="btn-group float-end" role="group" aria-label="Basic example">
                                    <span type="button" className={activeFilter === "Yearly" ? "btn visits_btn " : "btn "}
                                        onClick={() => setactiveFilter("Yearly")}
                                    >
                                        Yearly
                                    </span>
                                    <span type="button" className={activeFilter === "Monthly" ? "btn visits_btn " : "btn "}
                                        onClick={() => setactiveFilter("Monthly")}
                                    >
                                        Monthly
                                    </span>
                                    <span type="button" className={activeFilter === "Weekly" ? "btn visits_btn " : "btn "}
                                        onClick={() => setactiveFilter("Weekly")}
                                    >
                                        Weekly
                                    </span>
                                    <span type="button" className={activeFilter === "Daily" ? "btn visits_btn " : "btn "}
                                        onClick={() => setactiveFilter("Daily")}
                                    >
                                        Daily
                                    </span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12 col-lg-6  py-3 mt-4 ">
                            {/* <span style={{ color: "#000000d1", fontWeight: "600", fontSize: "16px" }}> Target Order</span>
                            <span className='float-end' style={{ color: "#000000d1", fontWeight: "600", fontSize: "16px" }}> Order Achievement</span> */}
                            <div className='card materials_stocks'>
                                <div className="row ot_col ">

                                    <div className="col-xl-6 col-md-6 col-sm-5 mt-3 bottom_border right_borders total-target-border" style={{ cursor: "pointer" }} >
                                        <TotalTargetArchievedCard
                                            count={
                                                activeFilter === "Yearly" ? Countdata ? Countdata.TotalOrderTarget : '0' : activeFilter === "Monthly" ? "0" :
                                                    activeFilter === "Weekly" ? "0" : "0"
                                            }
                                            name="Order Target (Nos)"
                                            targetOrderNavigate={targetOrderNavigate}
                                            screenFlag="Target"
                                        />
                                    </div>
                                    {/* <hr /> */}
                                    <div className="col-xl-6 col-md-6 col-sm-5 mt-3 p-1 " style={{ cursor: "pointer" }}>
                                        <TotalTargetArchievedCard
                                            count={
                                                activeFilter === "Yearly" ? Countdata ? Countdata.TotalOrderAchivement : '0' : activeFilter === "Monthly" ? "0" :
                                                    activeFilter === "Weekly" ? "0" : "0"
                                            }
                                            name="Order Achievement (Nos)"
                                            targetOrderNavigate={targetOrderNavigate}
                                            screenFlag="Target"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6  py-3 mt-4">
                            {/* <span style={{ color: "#000000d1", fontWeight: "600", fontSize: "16px" }}> Financial Target</span>
                            <span className='float-end' style={{ color: "#000000d1", fontWeight: "600", fontSize: "16px" }}> Achievement</span> */}
                            <div className='card materials_stocks' >
                                <div className="row ot_col">
                                    <div className="col-xl-6 col-md-6 col-sm-6 mt-3 bottom_border right_borders  total-target-border " style={{ cursor: "pointer" }} >
                                        <TotalTargetArchievedCard
                                            count={
                                                activeFilter === "Yearly" ? Countdata ? Countdata.TotalFinancialTarget : '0' : activeFilter === "Monthly" ? "0" :
                                                    activeFilter === "Weekly" ? "0" : "0"
                                            }
                                            name="Financial Target (Lacs)"
                                            targetOrderNavigate={targetOrderNavigate}
                                            screenFlag="Financial"
                                        />
                                    </div>
                                    <div className="col-xl-6 col-md-6 col-sm-6 mt-3 p-1 " style={{ cursor: "pointer" }}>
                                        <TotalTargetArchievedCard
                                            count={
                                                activeFilter === "Yearly" ? Countdata ? Countdata.TotalFinancialAchivement : '0' : activeFilter === "Monthly" ? "0" :
                                                    activeFilter === "Weekly" ? "0" : "0"
                                            }
                                            name="Financial Achievement (Lacs)"
                                            targetOrderNavigate={targetOrderNavigate}
                                            screenFlag="Financial"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div className="col-lg-5 col-md-10 col-9 pt-5 chart_1 card_big3 borders mt_10" >
                <div className='row mt-3'>
                    <div className='col-lg-12'>
                        <div className="card-header font-weight-bold ">
                            <ul className="nav nav-tabs float-end border">
                                <li onClick={() => setActiveClass("Target")}>
                                    <a data-toggle="tab"
                                        className={activeClass === "Target" ? "active" : ""}>Order Target</a>
                                </li>
                                <li onClick={() => setActiveClass("Achievement")}>
                                    <a data-toggle="tab"
                                        className={activeClass === "Achievement" ? "active" : ""}>Financial Target</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mt-2">
                    {
                        activeClass === "Target" ?
                            <div>
                                <p className="fw-bold pb-3">Officer Target & Achievement</p>
                                <Bar options={TargetOptions}
                                    data={Targetdata}
                                />
                            </div>
                            :
                            <div>
                                <p className="fw-bold pb-3">Officer Target & Achievement</p>
                                <Bar options={FinancialOptions}
                                    data={Financialdata}
                                />
                            </div>

                    }

                </div>
            </div>
        </>
    )
}


