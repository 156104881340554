

export const options = {
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                const label = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = tooltipItem.yLabel;
                return `${label}: ${value} Nos`;
            }
        }
    },

    layout: {
        padding: {
            // top: 70,
            // bottom: 10
        }
    },
    legend: {
        // position: 'chartArea',
        // align: 'start',
    },
    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black'
        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 10,
            font: {
                resizable: true,
                minSize: 10,
                maxSize: 16
            }
        },
    },

    scales: {

        xAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            barThickness: 30,

            ticks: {
                autoSkip: false,

            }
        }],

        yAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            ticks: {
                beginAtZero: true
            }
        }]
    }
}

// export const targetFinancialdata = {
//     labels: ['Order Target', 'Order Achievement', 'Financial Target', 'Financial Achievement'],
//     datasets: [
//         {
//             label: 'Target Order',
//             data: [51006, 61344, 50344, 50000],
//             backgroundColor: [
//                 '#7978e9', '#dc3912', '#fa7902', '#109618'
//             ],
//             // borderWidth: 1,
//         },

//     ],
// };

export const TargetOptions = {
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                const label = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = tooltipItem.yLabel;
                return `${label}: ${value} Nos`;
            }
        }
    },

    layout: {
        padding: {
            // top: 70,
            // bottom: 10
        }
    },
    legend: {
        display: false
    },
    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black',
            
        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 15,
            font: {
                resizable: true,
                minSize: 12,
                maxSize: 16
            }
        },
    },

    scales: {

        xAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            barThickness: 30,

            ticks: {
                autoSkip: false,

            }
        }],

        yAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            ticks: {
                beginAtZero: true
            }
        }]
    }
}


export const FinancialOptions = {
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                const label = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = tooltipItem.yLabel;
                return `${label}: ${value} Lacs`;
            }
        }
    },

    layout: {
        padding: {
            // top: 70,
            // bottom: 10
        }
    },
    legend: {
        // position: 'chartArea',
        // align: 'start',
        display: false
    },
    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black'
        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 15,
            font: {
                resizable: true,
                minSize: 12,
                maxSize: 16
            }
        },
    },

    scales: {

        xAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            barThickness: 30,

            ticks: {
                autoSkip: false,

            }
        }],

        yAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            ticks: {
                beginAtZero: true
            }
        }]
    }
}





// export const Dcdata = {
//     labels: ['Order Generated', 'Dc Created', 'Dc Pending'],
//     datasets: [
//         {
//             label: 'Target Order',
//             data: [125, 90, 35, 150],
//             backgroundColor: [
//                 // '#7978e9', '#dc3912', '#fa7902', '#109618'
//                 '#3b6deb', '#28c8c1', '#7a31f4', '#109618'
//             ],
//             // borderWidth: 1,
//         },
//     ],
// };
export const Dcoptions = {
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                const label = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = tooltipItem.yLabel;
                return `${label}: ${value} `;
            }
        }
    },

    layout: {
        padding: {
            // top: 70,
            // bottom: 10
        }
    },
    legend: {
        // position: 'chartArea',
        // align: 'start',
        display: false
    },
    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black'
        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 15,
            font: {
                resizable: true,
                minSize: 12,
                maxSize: 16
            }
        },
    },

    scales: {

        xAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            barThickness: 30,
            ticks: {
                autoSkip: false,

            }
        }],
        yAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            ticks: {
                beginAtZero: true
            }
        }]
    }
}


export const Invoiceoptions = {
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                const label = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = tooltipItem.yLabel;
                return `${label}: ${value} `;
            }
        }
    },

    layout: {
        padding: {
            // top: 70,
            // bottom: 10
        }
    },
    legend: {
        // position: 'chartArea',
        // align: 'start',
        display: false
    },
    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black'
        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 15,
            font: {
                resizable: true,
                minSize: 12,
                maxSize: 16
            }
        },
    },

    scales: {

        xAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            barThickness: 30,
            ticks: {
                autoSkip: false,

            }
        }],
        yAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            ticks: {
                beginAtZero: true
            }
        }]
    }
}




export const Paymentoptions = {
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                const label = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = tooltipItem.yLabel;
                return `${label}: ${value} Lacs`;
            }
        }
    },

    layout: {
        padding: {
            // top: 70,
            // bottom: 10
        }
    },
    legend: {
        // position: 'chartArea',
        // align: 'start',
    },
    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black'
        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 15,
            font: {
                resizable: true,
                minSize: 12,
                maxSize: 16
            }
        },
    },

    scales: {

        xAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            barThickness: 30,
            ticks: {
                autoSkip: false,

            }
        }],
        yAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            ticks: {
                beginAtZero: true
            }
        }]
    }
}

export const assistantVisitsData = {
    labels: ['Target', 'Completed', 'Pending'],
    datasets: [
        {
            label: 'Field Target',
            data: [70, 40, 20, 100],
            backgroundColor: [
                '#7978e9', '#dc3912', '#fa7902', '#109618'
            ],
            // borderWidth: 1,
        },
        {
            label: 'Field Day Description',
            data: [60, 40, 20, 100],
            backgroundColor: [
                '#109618', '#dc3912', '#fa7902', '#109618'
            ],
            // borderWidth: 1,
        },
    ],
};

export const assistantVisitsOptions = {
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                const label = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = tooltipItem.yLabel;
                return `${label}: ${value} `;
            }
        }
    },

    layout: {
        padding: {
            // top: 70,
            // bottom: 10
        }
    },
    legend: {
        // position: 'chartArea',
        // align: 'start',
    },
    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black'
        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 10,
            font: {
                resizable: true,
                minSize: 10,
                maxSize: 16
            }
        },
    },

    scales: {

        xAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            barThickness: 30,

            ticks: {
                autoSkip: false,

            }
        }],

        yAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            ticks: {
                beginAtZero: true
            }
        }]
    }
}



export const FarmerMeetingDemonstrationOptions = {
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                const label = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = tooltipItem.yLabel;
                return `${label}: ${value} `;
            }
        }
    },

    layout: {
        padding: {
            // top: 70,
            // bottom: 10
        }
    },
    legend: {
        // position: 'chartArea',
        // align: 'start',
    },
    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black'
        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 10,
            font: {
                resizable: true,
                minSize: 10,
                maxSize: 16
            }
        },
    },

    scales: {

        xAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            barThickness: 30,

            ticks: {
                autoSkip: false,

            }
        }],

        yAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            ticks: {
                beginAtZero: true
            }
        }]
    }
}


// export const Expensedata = {
//     labels: ['Travelling Expenses', 'Lodging Expenses','Field Day Expenses'],
//     datasets: [
//         {
//             label: ['Expenses'],
//             data: [80000, 30000, 50344, 100000],
//             backgroundColor: [
//                 // '#7978e9', '#dc3912', '#fa7902', '#109618'
//                 '#3b6deb', '#28c8c1', '#7a31f4', '#109618'
//             ],
//             // borderWidth: 1,
//         },
//     ],
// };
export const ExpenseOption = {

    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                const label = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = tooltipItem.yLabel;
                return `${label}: ${value} `;
            }
        }
    },

    layout: {
        padding: {
            // top: 70,
            // bottom: 10
        }
    },
    legend: {
        // position: 'chartArea',
        // align: 'start',
        display: false
    },
    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black'
        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 15,
            font: {
                resizable: true,
                minSize: 12,
                maxSize: 16
            }
        },
    },

    scales: {

        xAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            barThickness: 30,
            ticks: {
                autoSkip: false,

            }
        }],
        yAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            ticks: {
                beginAtZero: true
            }
        }]
    }

}
