

import { useEffect } from 'react'
import Select from 'react-select'
import { AstricSign } from '../../Helper/AstricSign'

export const RMOfficerNameDDL = (props) => {
    const { RMOfficerDDL, setRMOfficerDDL, ReportOfficerNameDDLData, mandatory } = props

    useEffect(() => {
        handleOfficerNameDDL()
    }, [ReportOfficerNameDDLData])

    const handleOfficerNameDDL = () => {
        // console.log(DeptDDLDataa)
        if (ReportOfficerNameDDLData && ReportOfficerNameDDLData.table && ReportOfficerNameDDLData.table.length > 0) {
            let list = ReportOfficerNameDDLData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.m_EmployeeID,
                label: item.employeeName,
            }))

            setRMOfficerDDL({
                DDL: list,
                ID:  0 ,
                Label: "Select..."
            })
        }
        else {
            setRMOfficerDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment">Officer Name {mandatory && <AstricSign />}</label>
            <Select
                // isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: RMOfficerDDL.ID, label: RMOfficerDDL.Label }}
                onChange={(e) => {
                    e ?
                    setRMOfficerDDL({ ...RMOfficerDDL, ID: e.value, Label: e.label })
                        :
                        setRMOfficerDDL({ ...RMOfficerDDL, ID: 0, Label: "Select..." })

                }}
                options={RMOfficerDDL.DDL}
            />
        </div>
    )
}