import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import Header from "../../../../Components/Header/Header";
import Sidebar from "../../../../Components/Sidebar/Sidebar";
import InvoiceGeneratePopUp from "../../../Private/DashBoard/DashboardPages/InvoicesAdminTable/InvoiceGenerate/InvoiceGeneratePopUp";

import ReactHTMLTableToExcel from "react-html-table-to-excel";
import QuantityPopUpCommon from "../../../Private/DashBoard/DashboardPages/DCStoreTable/QuantityPopUpCommon";
import { AstricSign } from "../../../../Helper/AstricSign";
import ForwardPop from "./ForwardPop";
import DispatchPop from "./DispatchPop";
import { DealerNameDDLAPI, DistrictDashboardDDLAPI, DistrictNameDDLAPI, MonthDDLAPI, OfficerNameDDLAPI, SeasonDDLAPI, StateDashboardDDLAPI, StateNameDDLAPI, TalukaNameDDLAPI } from "../../../../Redux/DDLSlice";
import { ApprovalOrderExportTableDataAPI, ApprovalOrderTableDataAPI, ForwardStorePopUpPostAPI } from "../../../../Redux/StateInChargeSlice/ApprovalOrderSlice";
import { StateNameDataDDL } from "../../../../Components/CommonDDL/StateNameDataDDL";
import { SeasonDataDDL } from "../../../../Components/CommonDDL/SeasonDataDDL";
import { DistrictNameDataDDL } from "../../../../Components/CommonDDL/DistrictNameDataDDL";
import { OfficerNameDataDDL } from "../../../../Components/CommonDDL/OfficerNameDataDDL";
import { useAuthState } from "../../../../Helper/Context";
import { useDispatch, useSelector } from "react-redux";
import { Pegination } from "../../../../Components/Pegination/Pegination";
import ProductDetailsPopUp from "../../StageInCharge/ApprovalOrder/ProductDetailsPopUp";
import { Loading } from "../../../../Helper/Loading";
import { ReceivedDealearDDL } from "../../../../Components/CommonDDL/ReceivedDealearDDL";
import { StateDashboardDDL } from "../../../../Components/CommonDDL/StateDashboardDDL";
import { DistrictDashboardDDL } from "../../../../Components/CommonDDL/DistrictDashboardDDL";

export default function ReceivedInvoiceStore() {

  const userDetails = useAuthState();
  const { UserID, token } = userDetails
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  let ScreenName = searchParams.get("ScreenName")
  // let Flag = searchParams.get("Flag")
  // ----------pagination-------------
  const [PerPageCount, setPerPageCount] = useState(10)
  const [TotalCount, setTotalCount] = useState(0)
  const [To, setTo] = useState(10)
  const [From, setFrom] = useState(1)
  const [rowNo, setrowNo] = useState(1)
  const [CurrentPage, setCurrentPage] = useState(0)

  const [IsSearch, setIsSearch] = useState(false)
  const [IsClear, setIsClear] = useState(false)
  const [IsPost, setIsPost] = useState(false)
  const [Remark, setRemark] = useState("")
  const [InvoiceDate, setInvoiceDate] = useState('')
  const [InvoiceNo, setInvoiceNo] = useState('')
  const [YearValue, setYearValue] = useState(0)

  const [PopUpField, setPopUpField] = React.useState({
    addPopUp: false,
    deletePopUp: false,
    popupFlag: '',
    popupBtn: "",
    apiFlag: "",
    rowData: '',
    rejectPopUp: '',
    approvedRejFlag: '',
    Remark: '',
    ApprovedRejectPopUp: '',
    ProductDetailsPopUp: '',
    ForwardGeneratePopUp: '',
    DispatchGeneratePopUp: '',

  })

  const [StateDDL, setStateDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select...",
  })

  const [SeasonDDL, setSeasonDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select..."
  })
  const [MonthDDL, setMonthDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select...",
  })

  const [DistrictDDL, setDistrictDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select...",
  })

  const [StatusDDL, setStatusDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select...",
  })

  const [TalukaDDL, setTalukaDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select...",
  })

  const [DealerDDL, setDealerDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select...",
  })
  const [OfficerNameDDL, setOfficerNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select...",
  })

  const [StoreNameDDL, setStoreNameDDL] = useState({
    DDL: [],
    ID: 0,
    Label: "Select...",
  })

  const [ApprovedRejectPopUp, setApprovedRejectPopUp] = React.useState()
  const [ProductDetailsPopUpHeading, setProductDetailsPopUpHeading] = useState()
  const [createActionPopUp, setcreateActionPopUp] = React.useState()
  const [QuantityPopUpHeading, setQuantityPopUpHeading] = useState();

  const [invoiceGeneratePopUp, setinvoiceGeneratePopUp] = React.useState();

  const [forwardGeneratePop, setForwardGeneratePop] = useState()
  const [dispatchGeneratePop, setDispatchGeneratePop] = useState()

  // const quantityPopUpClick = () => {
  //   setinvoiceGeneratePopUp(true);
  // };

  const handleForwardPop = () => {
    setForwardGeneratePop(true)
  }

  const handleDispatchPop = () => {
    setDispatchGeneratePop(true)
  }

  // const handleCloseClick = () => {
  //   setinvoiceGeneratePopUp(false);
  //   setForwardGeneratePop(false)
  //   setDispatchGeneratePop(false)
  // };

  const handleForwardClose = () => {

  }

  const handleSearch = () => {
    setIsSearch(!IsSearch)
    setCurrentPage(0)
    setFrom(1)
    setTo(10)
  }

  const handleClear = () => {
    setCurrentPage(0)
    setIsClear(!IsClear)
    setInvoiceDate('')
    setInvoiceNo('')
    setStateDDL({
      ...StateDDL,
      ID: 0,
      Label: "Select...",
    })
    setSeasonDDL({
      ...SeasonDDL,
      ID: 0,
      Label: "Select...",
    })

    setMonthDDL({
      ...MonthDDL,
      ID: 0,
      Label: "Select...",
    })
    setStatusDDL({
      ...StatusDDL,
      ID: 0,
      Label: "Select...",
    })
    setDistrictDDL({
      ...DistrictDDL,
      ID: 0,
      Label: "Select...",
    })
    setTalukaDDL({
      ...TalukaDDL,
      ID: 0,
      Label: "Select...",
    })
    setDealerDDL({
      ...DealerDDL,
      ID: 0,
      Label: "Select...",
    })
  }

  const handlePost = () => {
    setIsPost(!IsPost)
  }

  const productDetailsPopUpClick = (item) => {
    setPopUpField({ ...PopUpField, ProductDetailsPopUp: true, rowData: item })
  }

  const handleClosePopUpClick = (item) => {
    setPopUpField({ ...PopUpField, ProductDetailsPopUp: false, rowData: item })
  }
  const handleForwardPopUpClick = (item) => {
    setPopUpField({ ...PopUpField, ForwardGeneratePopUp: true, rowData: item })
  }

  const handleForwardClosePopUpClick = (item) => {
    setPopUpField({ ...PopUpField, ForwardGeneratePopUp: false, rowData: item })
  }

  const handleDispatchPopUp = (item) => {
    setPopUpField({ ...PopUpField, DispatchGeneratePopUp: true, rowData: item })
  }
  const handleDispatchClosePopUp = (item) => {
    setPopUpField({ ...PopUpField, DispatchGeneratePopUp: false, rowData: item })
  }

  // useEffect(() => {
  //   const data = {
  //     UserID,
  //     token,
  //     ShowBy: 'Web',
  //     ScreenName: ScreenName
  //   }
  //   dispatch(StateNameDDLAPI({ data, Flag: 'InvoiceCreation' }))
  // }, [])
  useEffect(() => {
    const data = { UserID, token, M_MonthID: '0', M_FinancialYearID: YearValue }
    if (YearValue !== 0) {
      dispatch(StateDashboardDDLAPI({ data, Flag: 'Store_RecivedOrder' }))
    }
  }, [YearValue])

  const { StateDashboardDDLData } = useSelector(state => state.StateDashboardDDLData)

  useEffect(() => {
    const data = {
      UserID,
      token,
      ShowBy: 'Web',
      ScreenName: ScreenName
    }
    dispatch(SeasonDDLAPI({ data, Flag: 'InvoiceCreation' }))
  }, [])
  const { SeasonDDLData } = useSelector(state => state.SeasonDDLData)


  // ----Month DDL -------

  useEffect(() => {
    const data = {
      UserID,
      token,
      SeasonDDL,
      ShowBy: 'Web',
      ScreenName: ScreenName
    }
    dispatch(MonthDDLAPI({ data, Flag: 'InvoiceCreation' }))
  }, [SeasonDDL.ID])


  const { MonthData } = useSelector(state => state.MonthDDLData)

  useEffect(() => {
    handleMonthDDL()
  }, [MonthData])

  const handleMonthDDL = () => {
    if (MonthData && MonthData.table && MonthData.table.length > 0) {
      let list = MonthData.table.map((item, index) => ({
        value: item.id,
        label: item.month_Name,
      }))

      setMonthDDL({
        DDL: list,
        ID: 0,
        Label: "Select..."
      })
    }
    else {
      setMonthDDL({
        DDL: [],
        ID: 0,
        Label: "Select...",
      })
    }

  }

  useEffect(() => {
    const data = {
      StateDDL,
      UserID,
      token,
      M_MonthID: '0',
      M_FinancialYearID: YearValue
    }
    if (YearValue !== 0) {
      dispatch(DistrictDashboardDDLAPI({ data, Flag: 'Store_RecivedOrder' }))
    }
  }, [StateDDL.ID, YearValue])

  const { DistrictDashboardDDLData } = useSelector(state => state.DistrictDashboardDDL)

  useEffect(() => {
    const data = { UserID, token, DistrictDDL }
    dispatch(TalukaNameDDLAPI({ data }))
  }, [DistrictDDL.ID])

  const { TalukaDDLData } = useSelector(state => state.TalukaNameDDL)

  useEffect(() => {
    const data = {
      UserID, token, DistrictDDL,
      // Flag: Flag
    }
    dispatch(DealerNameDDLAPI({ data, Flag: 'Store_ReceivedOrder' }))
  }, [DistrictDDL.ID])

  const { DealerNameDDLData } = useSelector(state => state.DealerNameDDLData);

  useEffect(() => {
    const data = {
      UserID,
      token,
      StateDDL,
      // TalukaDDL,
      DistrictDDL,
      ShowBy: 'Web',
      // ScreenName: ScreenName,         
    }
    dispatch(OfficerNameDDLAPI({ data, Flag: 'Store_ReceivedOrder', }))
  }, [StateDDL.ID, DistrictDDL.ID])

  const { OfficerNameDDLData } = useSelector(state => state.OfficerNameDDLDataa);

  useEffect(() => {
    const data = {
      T_OrderPunchID: 0,
      M_FinancialYearID: YearValue,
      MonthID: MonthDDL.ID,
      M_SeasonID: SeasonDDL.ID,
      M_StateID: StateDDL.ID,
      M_DistrictNameID: DistrictDDL.ID,
      M_TalukaNameID: TalukaDDL.ID,
      M_DealerID: DealerDDL.ID,
      OrderDate: InvoiceDate,
      InvoiceNumber: InvoiceNo,
      M_StatusID: StatusDDL.ID,
      UserID: UserID,
      token: token,
      From: From,
      To: To,
      Flag: 'Admin_InvoiceCreation'
    }
    if (YearValue !== 0) {
      dispatch(ApprovalOrderTableDataAPI({ data }))
    }
  }, [To, IsPost, From, IsClear, IsSearch, SeasonDDL.ID, StateDDL.ID, DistrictDDL.ID, TalukaDDL.ID, MonthDDL.ID, StatusDDL.ID, DealerDDL.ID, YearValue])

  useEffect(() => {
    const data = {
      T_OrderPunchID: 0,
      M_FinancialYearID: YearValue,
      MonthID: MonthDDL.ID,
      M_SeasonID: SeasonDDL.ID,
      M_StateID: StateDDL.ID,
      M_DistrictNameID: DistrictDDL.ID,
      M_TalukaNameID: TalukaDDL.ID,
      M_DealerID: DealerDDL.ID,
      OrderDate: InvoiceDate,
      InvoiceNumber: InvoiceNo,
      M_StatusID: StatusDDL.ID,
      UserID: UserID,
      token: token,
      From: From,
      To: To,
      Flag: 'Admin_InvoiceCreation'
    }
    if (YearValue !== 0) {
      dispatch(ApprovalOrderExportTableDataAPI({ data }))
    }
  }, [To, IsPost, From, IsClear, IsSearch, SeasonDDL.ID, StateDDL.ID, DistrictDDL.ID, TalukaDDL.ID, MonthDDL.ID, StatusDDL.ID, DealerDDL.ID, YearValue])


  const { tableData, isLoading } = useSelector(state => state.ApprovalOrderTableData)
  const { ApprovalOrderExportTableData, isExportLoading } = useSelector(state => state.ApprovalOrderExportTableData)

  useEffect(() => {
    setCurrentPage(0)
    setFrom(1)
    setTo(10)
    // handleSearch()  
  }, [SeasonDDL.ID, StateDDL.ID, DistrictDDL.ID, TalukaDDL.ID, MonthDDL.ID, StatusDDL.ID, DealerDDL.ID])

  const handleForwarded = () => {
    const data = {
      T_OrderPunchID: PopUpField.rowData?.t_OrderPunchID,
      M_StoreUserID: StoreNameDDL.ID,
      M_FinancialYearID: YearValue,
      M_MonthID: '0',
      M_UserID: UserID,
      token: token,
      Flag: "Forwarded",
      handlePost: handlePost,
      handleAddCloseClick: handleForwardClosePopUpClick
    }
    dispatch(ForwardStorePopUpPostAPI({ data }))
  }

  return (
    <>
      {isLoading && <Loading />}
      <main className="main-content position-relative border-radius-lg ">
        <Header setYearValue={setYearValue} />
        <Sidebar active='ReceivedInvoiceStore' />
        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid xyz">
              <div className="row mt_40">
                <div className="row mtop">
                  <div className="col-md-12 grid-margin">
                    <div className="row page-heading">
                      <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                        <h4 className="fontStyle">
                          Received Order
                          {/* {`${ScreenName}`}{" "} */}
                        </h4>
                      </div>
                      <div className="col-12 col-lg-4 mb-xl-0">
                        <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="download-table-xls-button float-end btn btn-export btn-md pt-1 pb-1 pl-3 pr-3"
                          table="totalordergenerated"
                          filename="data"
                          sheet="data"
                          pageOrientation="Landscape"
                          buttonText="Export"
                          style={{ borderColor: "black" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 shadow table-card mt-1 mx-2">
                    <div className="filter mb-2 mt-2">
                      <div className="card-body">
                        <div className="filter-bg p-2">
                          <div className="row">

                            {/* <div className="col-md-6 col-lg-2">
                              <StateDashboardDDL
                                StateDDL={StateDDL}
                                setStateDDL={setStateDDL}
                                StateDashboardDDLData={StateDashboardDDLData}
                                mandatory={true}
                              />
                            </div> */}
                            <div className="col-md-6 col-lg-2">
                              <SeasonDataDDL
                                SeasonDDL={SeasonDDL}
                                setSeasonDDL={setSeasonDDL}
                                SeasonDDLData={SeasonDDLData}
                                mandatory={true}
                              />
                            </div>
                            <div className="col-md-6 col-lg-2">
                              <div className="form-group">
                                <label className="d-block" htmlFor="NameofDepartment">Month<AstricSign /></label>
                                <Select
                                  // isClearable
                                  // isRtl={isRtl}
                                  isSearchable
                                  maxMenuHeight={150}
                                  value={{ value: MonthDDL.ID, label: MonthDDL.Label }}
                                  onChange={(e) => {
                                    e ?
                                      setMonthDDL({ ...MonthDDL, ID: e.value, Label: e.label })
                                      :
                                      setMonthDDL({ ...MonthDDL, ID: 0, Label: "Select..." })

                                  }}
                                  options={MonthDDL.DDL}
                                />
                              </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                              <DistrictDashboardDDL
                                DistrictDDL={DistrictDDL}
                                setDistrictDDL={setDistrictDDL}
                                DistrictDashboardDDLData={DistrictDashboardDDLData}
                              // mandatory={true}
                              // searchName={searchName}
                              />
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <OfficerNameDataDDL
                                OfficerNameDDL={OfficerNameDDL}
                                setOfficerNameDDL={setOfficerNameDDL}
                                OfficerNameDDLData={OfficerNameDDLData}
                              />
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <ReceivedDealearDDL
                                DealerDDL={DealerDDL}
                                setDealerDDL={setDealerDDL}
                                DealerNameDDLData={DealerNameDDLData}
                              />
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <div className="form-group">
                                <label className="d-block" htmlFor="NameofDepartment">Invoice No</label>
                                <input
                                  type="text"
                                  className='form-control'
                                  name='InvoiceNo'
                                  value={InvoiceNo}
                                  onChange={(e) => setInvoiceNo(e.target.value)}
                                />
                              </div>
                            </div>

                            <div className="col-md-6 col-lg-3">
                              <div className="form-group">
                                <label className="d-block" htmlFor="NameofDepartment">Invoice Date </label>
                                <input
                                  type="date"
                                  className='form-control'
                                  name='InvoiceDate'
                                  value={InvoiceDate}
                                  onChange={(e) => setInvoiceDate(e.target.value)}
                                />
                              </div>
                            </div>


                            {/* <div className="col-md-6 col-lg-3">
    <div className="form-group">
        <label className="d-block" htmlFor="NameofDepartment">Order Date</label>
        <input
            className="form-control"
            id="Date"
            type="date"
            name="Date"
            value={Date}
            onChange={(e) => setDate(e.target.value)}
        />
    </div>
</div>
<div className="col-md-6 col-lg-3">
    <div className="form-group">
        <label className="d-block" htmlFor="NameofDepartment">Status</label>
        <Select
            isClearable
            // isRtl={isRtl}
            isSearchable
            maxMenuHeight={150}
            value={{ value: StatusDDL.ID, label: StatusDDL.Label }}
            onChange={(e) => {
                e ?
                    setStatusDDL({ ...StatusDDL, ID: e.value, Label: e.label })
                    :
                    setStatusDDL({ ...StatusDDL, ID: 0, Label: "Select..." })

            }}
            options={StatusDDL.DDL}
        />
    </div>
</div> */}
                            <div className="col-12 col-lg-3 clear">
                              <button type="button" className="btn addBtn text-white mr-2 mt-4 mt-md-0 mt-lg-4 mx-2 waves-effect waves-light allBtn"
                                onClick={() => handleSearch()}
                              >
                                Search
                              </button>
                              <button type="button" className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 mx-2 waves-effect waves-light allBtn"
                                onClick={handleClear}
                              >
                                Clear
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive ">
                      <table
                        id="totalordergenerated"
                        cellPadding="0"
                        cellSpacing="0"
                        border="0"
                        className="table table-bordered"
                      >
                        <thead>
                          <tr>
                            <th>
                              Sr.No.
                            </th>
                            <th>State</th>
                            <th>Season</th>
                            <th>District</th>
                            <th>invoice Date
                              < hr style={{ color: "#ffff" }} />
                              invoice No</th>
                            <th>Order Date
                              < hr style={{ color: "#ffff" }} />
                              Order No</th>
                            <th>Dealer Name</th>
                            <th>Product Quantity</th>
                            <th>Order <br /> Amount (Rs)</th>
                            <th>Officer Name</th>
                            <th>Order Book</th>
                            <th>Invoice <br /> Document</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => (
                              <tr key={i}>
                                <td style={{ textAlign: 'center' }} className='w-5'>{item.rowNum}</td>
                                <td>{item.stateName ? item.stateName : '-'}</td>
                                <td>{item.seasonName ? item.seasonName : '-'}</td>
                                <td>{item.districtName ? item.districtName : '-'}</td>
                                <td>
                                  {item.invoiceDate ? moment(item.invoiceDate).format("DD-MM-YYYY") : '-'}
                                  < hr style={{ color: "#172b4d" }} />
                                  {item.invoiceNumber ? item.invoiceNumber : '-'}
                                </td>
                                <td>
                                  {item.orderDate ? moment(item.orderDate).format("DD-MM-YYYY") : '-'}
                                  < hr style={{ color: "#172b4d" }} />
                                  {item.orderNumber ? item.orderNumber : '-'}
                                </td>

                                <td>{item.dealerName ? item.dealerName : '-'}</td>
                                <td style={{ textAlign: 'center' }}>
                                  <span
                                    title="Click"
                                    className="btn btn-outline-primary text-white mr-2 mt-md-0 mt-lg-1 mx-2 waves-effect waves-light"
                                    // onClick={() => productDetailsPopUpClick(item)}
                                    // onClick={() => productDetailsPopUpClick()}
                                    onClick={() => productDetailsPopUpClick(item)}
                                  >
                                    {item.totalOrderQuantity ? item.totalOrderQuantity : '-'}
                                  </span>
                                </td>

                                <td>{item.orderAmt ? item.orderAmt : '-'}</td>

                                <td>
                                  {item.employeeName ? item.employeeName : '-'}</td>

                                <td style={{ textAlign: 'center' }}>
                                  {
                                    <a target="_blank" style={{ textDecoration: "underline", fontSize: "16px" }}>
                                      <i class="fa-solid fa-file-pdf" style={{ fontSize: '1.3rem', color: 'red', cursor: 'pointer' }}
                                        onClick={() => {
                                          window.open(item.orderPunchPdf ? item.orderPunchPdf : 'ImageNotFound')
                                        }}
                                      ></i>
                                    </a>
                                  }
                                </td>

                                <td style={{ textAlign: 'center' }}>
                                  {
                                    <a target="_blank" style={{ textDecoration: "underline", fontSize: "16px" }}>
                                      <i class="fa fa-file " title='view' style={{ fontSize: "17px", cursor: "pointer" }}
                                        onClick={() => {
                                          window.open(item.invoiceDocument ? item.invoiceDocument : 'ImageNotFound')
                                        }}
                                      ></i>
                                    </a>
                                  }
                                </td>
                                <td className="p-1">
                                  <span onClick={() => handleForwardPopUpClick(item)}>
                                    <i
                                      title="Forward"
                                      class="fa-solid fa-share mx-2"
                                      style={{ cursor: "pointer", fontSize: "1rem" }}
                                    ></i>
                                  </span>
                                  <span onClick={() => handleDispatchPopUp(item)}>
                                    <i
                                      title="Dispatch"
                                      class="fa-solid fa-right-from-bracket mx-2 "
                                      style={{ cursor: "pointer", fontSize: "1rem" }}
                                    ></i>
                                  </span>
                                </td>
                              </tr>
                            )) : <tr>No data</tr>
                          }

                        </tbody>
                      </table>
                    </div>
                    {tableData && tableData.table && tableData.table.length > 0 &&
                      <Pegination
                        PerPageCount={PerPageCount}
                        TotalCount={tableData.table[0].totalCount}
                        setFrom={setFrom}
                        setTo={setTo}
                        setrowNo={setrowNo}
                        CurrentPage={CurrentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* {PopUpField.ProductDetailsPopUp ? (
        <QuantityPopUpCommon
          open={PopUpField.ProductDetailsPopUp}
          handleCloseClick={handleClosePopUpClick}
          QuantityPopUpHeading="Total Order generated Quantity"
        />
      ) : (
        <></>
      )} */}
      {
        PopUpField.ProductDetailsPopUp ?
          <ProductDetailsPopUp
            open={PopUpField.ProductDetailsPopUp}
            handleCloseClick={handleClosePopUpClick}
            ProductDetailsPopUpHeading='Product Details'
            PopUpField={PopUpField}
            Flag='Admin_Received'
          /> : <></>
      }
      {
        PopUpField.ForwardGeneratePopUp ? (
          <ForwardPop
            open={PopUpField.ForwardGeneratePopUp}
            handleCloseClick={handleForwardClosePopUpClick}
            QuantityPopUpHeading="Order Forward"
            handleForwarded={handleForwarded}
            StoreNameDDL={StoreNameDDL}
            setStoreNameDDL={setStoreNameDDL}
          />
        ) : (
          <></>
        )}
      {
        PopUpField.DispatchGeneratePopUp ?
          <DispatchPop
            open={PopUpField.DispatchGeneratePopUp}
            PopUpField={PopUpField}
            handleCloseClick={handleDispatchClosePopUp}
            handlePost={handlePost}
            QuantityPopUpHeading="Order Dispatched"
          />
          :
          <></>
      }

    </>
  );
}
