
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from "moment";

export const FieldAssistantDemonstrationExportData = (props) => {
    const { ExcelData, name, searchName, ApiFlag, RoleID } = props
    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-FarmerMasterExport"
                className="download-table-xls-button float-end btn btn-export btn-md pt-1 pb-1 pl-3 pr-3 mx-3"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />
            <table id="table-to-FarmerMasterExport" style={{ display: 'none', width: '100%' }}>

                <h5><span>&emsp;</span></h5>

                <h4 style={{ textAlign: 'center', fontSize: 16 }}>{name}</h4>

                <tr><td></td></tr>

                <thead className="thead-dark" >
                    <tr>
                        <th>Sr.No.</th>
                        {
                            RoleID == 7 || RoleID == 8 ?
                                <></>
                                :
                                <th>State</th>
                        }
                        <th>District </th>
                        <th>Season</th>
                        <th>Crop Name</th>
                        <th>Total Demonstration Assign Target</th>
                        {
                            searchName === "Completed" && ApiFlag === 'Completed' ?
                                <>
                                    <th>Completed Demonstration Target</th>
                                    <th>Pending Demonstration Target</th>
                                    <th>Officer Name</th>
                                </>

                                : searchName === "Pending" && ApiFlag === 'Pending' ?
                                    <>
                                        <th>Completed Demonstration Target</th>
                                        <th>Pending Demonstration Target</th>
                                        {/* <th>Officer Name</th> */}
                                    </>
                                    :
                                    <></>

                        }

                    </tr>
                </thead>
                <tbody>
                    {
                        ExcelData && ExcelData.table && ExcelData.table.length > 0 ? ExcelData.table.map((item, i) => (
                            <tr key={i}>
                                <td style={{ textAlign: 'center' }} className='w-5'>{i + 1}</td>
                                {
                                    RoleID == 7 || RoleID == 8 ?
                                        <></>
                                        :
                                        <td>{item.stateName ? item.stateName : '-'}</td>
                                }

                                <td>{item.districtName ? item.districtName : '-'}</td>
                                <td>{item.seasonName ? item.seasonName : '-'}</td>
                                <td>{item.cropName ? item.cropName : '-'}</td>
                                <td>{item.totalDemonstrationCount ? item.totalDemonstrationCount : '-'}</td>
                                {searchName === "Completed" && ApiFlag === 'Completed' ?
                                    <>
                                        <td>{item.completedDemonstrationTarget ? item.completedDemonstrationTarget : '-'}</td>
                                        <td>{item.pendingDemonstrationTarget ? item.pendingDemonstrationTarget : '-'}</td>
                                        <td>{item.employeeName ? item.employeeName : '-'}</td>
                                    </>
                                    : searchName === "Pending" && ApiFlag === 'Pending' ?
                                        <>
                                            <td>{item.completedDemonstrationTarget ? item.completedDemonstrationTarget : '-'}</td>
                                            <td>{item.pendingDemonstrationTarget ? item.pendingDemonstrationTarget : '-'}</td>
                                            {/* <td>{item.employeeName ? item.employeeName : '-'}</td> */}
                                        </>
                                        :
                                        <></>
                                }
                            </tr>
                        )) : <tr>No data</tr>
                    }
                </tbody>
            </table>
        </div>
    )
}