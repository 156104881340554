var url = window.location.origin
export const BaseUrl =
    // url === "http://gayatriagroerp.gkmarket.in" ? "http://gayatriagroerpapi.gkmarket.in"

         url === "http://demogayatriagroerp.gkmarket.in" ? "http://demogayatriagroerpapi.gkmarket.in"
            : url === "http://gayatrierp.in" ? "http://api.gayatrierp.in" 
            : url === "https://gayatrierp.in" ? "https://api.gayatrierp.in" :
                "http://api.gayatrierp.in"
                // "http://demogayatriagroerpapi.gkmarket.in"
                // "https://api.gayatrierp.in"


// "http://gayatriagroerpapi.gkmarket.in"


