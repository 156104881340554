

import { useEffect } from 'react'
import Select from 'react-select'
import { AstricSign } from '../../Helper/AstricSign'

export const RMDistrictOfficerDDL = (props) => {
    const { DistrictOfficerDDL, setDistrictOfficerDDL, DistrictOfficerDDLData} = props


    useEffect(() => {
        handleDDL()
    }, [DistrictOfficerDDLData])

    const handleDDL = () => {
        if (DistrictOfficerDDLData && DistrictOfficerDDLData.table && DistrictOfficerDDLData.table.length > 0) {
            let list = DistrictOfficerDDLData.table.map((item, index) => ({
                value: item.m_EmployeeID,
                label: item.employeeName,
            }))

            setDistrictOfficerDDL({
                DDL: list,
                ID: '0',
                Label:"Select..."
            })
        }
        else {
            setDistrictOfficerDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }


    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment">District Officer</label>
            <Select
                // isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: DistrictOfficerDDL.ID, label: DistrictOfficerDDL.Label }}
                onChange={(e) => {
                    e ?
                        setDistrictOfficerDDL({ ...DistrictOfficerDDL, ID: e.value, Label: e.label })
                        :
                        setDistrictOfficerDDL({ ...DistrictOfficerDDL, ID: 0, Label: "Select..." })

                }}
                options={DistrictOfficerDDL.DDL}
            />
        </div>
    )
}