import React from 'react'
import Popup from 'reactjs-popup'

export default function FieldAssistantDayPopUp({ open, handleCloseClick }) {
    return (
        <>
            <Popup open={open} closeOnDocumentClick={false} onClose={handleCloseClick}>
                <span className="close" onClick={handleCloseClick}>
                    &times;
                </span>
                <div className="call"> Field Assistant </div>
                <div className="modal-body">
                    <div className="row details-row">
                        <div className="table-responsive ">
                            <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center", width: "10%" }}>Sr.No.</th>
                                        <th>Farmer Name</th>
                                        <th>Land (Acres)</th>
                                        <th>Photo</th>
                                        <th>Video</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr style={{ textAlign: "center" }}>
                                        <td>1</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td style={{cursor:"pointer"}}><i className="fa fa-picture-o" aria-hidden="true"></i></td>
                                        <td style={{cursor:"pointer"}}><i className="fa fa-video-camera" aria-hidden="true"></i></td>
                                    </tr>

                                    <tr style={{ textAlign: "center" }}>
                                        <td>2</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td style={{cursor:"pointer"}}><i className="fa fa-picture-o" aria-hidden="true"></i></td>
                                        <td style={{cursor:"pointer"}}><i className="fa fa-video-camera" aria-hidden="true"></i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


            </Popup>
        </>
    )
}


