
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from "moment";

export const TotalOrderGeneratedExportData = (props) => {
    const { ExcelData, name, searchName, ApiFlag, RoleID } = props
    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-FarmerMasterExport"
                className="download-table-xls-button float-end btn btn-export btn-md pt-1 pb-1 pl-3 pr-3 mx-3"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-FarmerMasterExport" style={{ display: 'none', width: '100%' }}>

                <h5><span>&emsp;</span></h5>

                <h4 style={{ textAlign: 'center', fontSize: 16 }}>{name}</h4>

                <tr><td></td></tr>

                <thead className="thead-dark" >
                    <tr>
                        <th style={{ textAlign: "center", width: "5%" }}>Sr.No.</th>
                        {
                            RoleID == 7 || RoleID == 8 ?
                                <></>
                                :
                                <th>State</th>
                        }
                        <th>Season</th>
                        <th>District</th>
                        {
                            searchName === "DC Dispatched" ?
                                <>
                                    <th>Dc Date</th>
                                    <th>Dc No.</th>
                                </>
                                :
                                <></>
                        }
                        <th>invoice Date
                            < hr style={{ color: "#ffff" }} />
                            invoice No</th>
                        <th>Order Date
                            < hr style={{ color: "#ffff" }} />
                            Order No</th>
                        <th>Dealer Name</th>
                        {/* <th>Product Quantity</th> */}
                        <th>Order Amount (Rs)</th>
                        <th>Officer Name</th>
                        {
                            searchName === "DC Forwarded" ?
                                <>
                                    <th>Store Name</th>
                                    {/* <th>Invoice Document</th> */}
                                </>
                                :
                                ''

                        }
                        {/* {
                            searchName === "DC Dispatched" ?
                                <>
                                    <th>Order Book</th>
                                    <th>Invoice Document</th>
                                    <th> Dc Document</th>
                                </>
                                :
                                <>
                                    <th>Order Book</th>
                                    <th>Invoice Document</th>

                                </>

                        } */}


                    </tr>
                </thead>
                <tbody>
                    {
                        ExcelData && ExcelData.table && ExcelData.table.length > 0 ? ExcelData.table.map((item, i) => (
                            <tr key={i}>
                                <td style={{ textAlign: 'center' }} className='w-5'>{item.rowNum}</td>
                                {
                                    RoleID == 7 || RoleID == 8 ?
                                        <></>
                                        :
                                        <td>{item.stateName ? item.stateName : '-'}</td>
                                }
                                <td>{item.seasonName ? item.seasonName : '-'}</td>
                                <td>{item.districtName ? item.districtName : '-'}</td>
                                {
                                    searchName === "DC Dispatched" ?
                                        <>
                                            <td>{item.dC_Date ? moment(item.dC_Date).format("DD-MM-YYYY") : '-'}</td>
                                            <td>{item.dC_No ? item.dC_No : '-'}</td>

                                        </>
                                        :
                                        <></>
                                }
                                <td>
                                    {item.invoiceDate ? moment(item.invoiceDate).format("DD-MM-YYYY") : '-'}
                                    <hr></hr>
                                    {item.invoiceNumber ? item.invoiceNumber : '-'}

                                </td>
                                <td>
                                    {item.orderDate ? moment(item.orderDate).format("DD-MM-YYYY") : '-'}
                                    <hr></hr>
                                    {item.orderNumber ? item.orderNumber : '-'}

                                </td>
                                <td>{item.dealerName ? item.dealerName : '-'}</td>
                                {/* <td style={{ textAlign: 'center' }}>
                                    <span className="btn btn-outline-primary text-white mr-2 mt-md-0 mt-lg-1 mx-2 waves-effect waves-light"
                                        onClick={() => quantityPopUpClick()}
                                    >
                                        {item.totalOrderQuantity ? item.totalOrderQuantity : '-'}
                                    </span>
                                </td> */}
                                <td>{item.orderAmt ? item.orderAmt : '-'}</td>
                                <td>{item.employeeName ? item.employeeName : '-'}</td>
                                {
                                    searchName === "DC Forwarded" ?
                                        <>
                                            <td>{item.dealerName ? item.dealerName : '-'}</td>
                                        </>
                                        :
                                        ''

                                }
                                {/* {
                                    searchName === "DC Dispatched" ?
                                        <>
                                            <td style={{ textAlign: 'center' }}>
                                                {
                                                    <a target="_blank" style={{ textDecoration: "underline", fontSize: "16px" }}>
                                                        <i class="fa-solid fa-file-pdf" style={{ fontSize: '1.3rem', color: 'red', cursor: 'pointer' }}
                                                            onClick={() => {
                                                                window.open(item.orderPunchPdf ? item.orderPunchPdf : 'ImageNotFound')
                                                            }}
                                                        ></i>
                                                    </a>
                                                }
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {
                                                    <a target="_blank" style={{ textDecoration: "underline", fontSize: "16px" }}>
                                                        <i class="fa fa-file " title='view' style={{ fontSize: "17px", cursor: "pointer" }}
                                                            onClick={() => {
                                                                window.open(item.invoiceDocument ? item.invoiceDocument : 'ImageNotFound')
                                                            }}
                                                        ></i>
                                                    </a>
                                                }
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {
                                                    <a target="_blank" style={{ textDecoration: "underline", fontSize: "16px" }}>
                                                        <i class="fa fa-file " title='view' style={{ fontSize: "17px", cursor: "pointer" }}
                                                            onClick={() => {
                                                                window.open(item.orderPunchDispatchPdf ? item.orderPunchDispatchPdf : 'ImageNotFound')
                                                            }}
                                                        ></i>
                                                    </a>
                                                }
                                            </td>
                                        </>
                                        :
                                        <>
                                            <td style={{ textAlign: 'center' }}>
                                                {
                                                    <a target="_blank" style={{ textDecoration: "underline", fontSize: "16px" }}>
                                                        <i class="fa-solid fa-file-pdf" style={{ fontSize: '1.3rem', color: 'red', cursor: 'pointer' }}
                                                            onClick={() => {
                                                                window.open(item.orderPunchPdf ? item.orderPunchPdf : 'ImageNotFound')
                                                            }}
                                                        ></i>
                                                    </a>
                                                }
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {
                                                    <a target="_blank" style={{ textDecoration: "underline", fontSize: "16px" }}>
                                                        <i class="fa fa-file " title='view' style={{ fontSize: "17px", cursor: "pointer" }}
                                                            onClick={() => {
                                                                window.open(item.invoiceDocument ? item.invoiceDocument : 'ImageNotFound')
                                                            }}
                                                        ></i>
                                                    </a>
                                                }
                                            </td>

                                        </>

                                } */}
                            </tr>
                        )) : <tr>No data</tr>
                    }
                </tbody>
            </table>
        </div>
    )
}