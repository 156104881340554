
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from "moment";

export const ApprovalOrderExportExcel = (props) => {
    const { ExcelData, name, ScreenName, Flag, RoleID } = props
    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-FarmerMasterExport"
                className="download-table-xls-button float-end btn btn-export btn-md pt-1 pb-1 pl-3 pr-3 mx-3"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />


            {/* <table id="table-to-FarmerMasterExport" style={{ display: 'none', width: '100%' }}> */}
            {
                ScreenName === "Received" && Flag === "State_Received" ?
                    <table id="table-to-FarmerMasterExport" style={{ display: 'none', width: '100%' }}>
                        <h5><span>&emsp;</span></h5>

                        <h4 style={{ textAlign: 'center', fontSize: 16 }}>{name}</h4>

                        <tr><td></td></tr>
                        <thead>
                            <tr>
                                <th>Sr.No.</th>
                                <th>District</th>
                                <th>Dealer Name</th>
                                <th>Order Received Date</th>
                                {/* <th>Product Quantity</th> */}
                                <th>Order Amount (Rs)</th>
                                <th>Officer Name</th>

                            </tr>
                        </thead>
                        <tbody>

                            {
                                ExcelData && ExcelData.table && ExcelData.table.length > 0 ? ExcelData.table.map((item, i) => (
                                    <tr key={i}>
                                        <td style={{ textAlign: 'center' }} className='w-5'>{item.rowNum}</td>
                                        <td>{item.districtName ? item.districtName : '-'}</td>
                                        <td>{item.dealerName ? item.dealerName : '-'}</td>
                                        <td>{item.orderDate ? moment(item.orderDate).format("DD-MM-YYYY") : '-'}</td>
                                        {/* <td style={{ textAlign: 'center' }}>
                                            <span
                                                title='Click'
                                                className="btn btn-outline-primary text-white mr-2 mt-md-0 mt-lg-1 mx-2 waves-effect waves-light"
                                                onClick={() => productDetailsPopUpClick(item)}
                                            >
                                                {item.totalOrderQuantity ? item.totalOrderQuantity : '-'}
                                            </span>
                                        </td> */}
                                        <td>{item.orderAmt ? item.orderAmt.toFixed(2) : '-'}</td>
                                        <td>{item.employeeName ? item.employeeName : '-'}</td>
                                    </tr>
                                )) : <tr>No data</tr>
                            }

                        </tbody>
                    </table>
                    :
                    ScreenName === "Approved" && Flag === "State_Approval" ?
                        <table id="table-to-FarmerMasterExport" style={{ display: 'none', width: '100%' }}>
                            <h5><span>&emsp;</span></h5>

                            <h4 style={{ textAlign: 'center', fontSize: 16 }}>{name}</h4>

                            <tr><td></td></tr>
                            <thead>
                                <tr>
                                    <th>Sr.No.</th>
                                    <th>Order Date</th>
                                    <th>Order No</th>
                                    <th>District</th>
                                    <th>Dealer Name</th>
                                    {/* <th>Product Quantity</th> */}
                                    <th>Order Amount (Rs)</th>
                                    <th>Officer Name</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ExcelData && ExcelData.table && ExcelData.table.length > 0 ? ExcelData.table.map((item, i) => (
                                        <tr key={i}>
                                            <td style={{ textAlign: 'center' }} className='w-5'>{item.rowNum}</td>
                                            <td>{item.orderDate ? moment(item.orderDate).format("DD-MM-YYYY") : '-'}</td>
                                            <td>{item.orderNumber ? item.orderNumber : '-'}</td>
                                            <td>{item.districtName ? item.districtName : '-'}</td>
                                            <td>{item.dealerName ? item.dealerName : '-'}</td>
                                            {/* <td style={{ textAlign: 'center' }} >
                                                <span
                                                    title='Click'
                                                    className="btn btn-outline-primary text-white mr-2 mt-md-0 mt-lg-1 mx-2 waves-effect waves-light"
                                                    onClick={() => productDetailsPopUpClick(item)}
                                                >
                                                    {item.totalOrderQuantity ? item.totalOrderQuantity : '-'}
                                                </span>
                                            </td> */}
                                            <td>{item.orderAmt ? item.orderAmt.toFixed(2) : '-'}</td>
                                            <td>{item.employeeName ? item.employeeName : '-'}</td>

                                        </tr>
                                    )) : <tr>No data</tr>
                                }
                            </tbody>
                        </table>
                        :
                        <table id="table-to-FarmerMasterExport" style={{ display: 'none', width: '100%' }}>
                            <h5><span>&emsp;</span></h5>

                            <h4 style={{ textAlign: 'center', fontSize: 16 }}>{name}</h4>

                            <tr><td></td></tr>
                            <thead>
                                <tr>
                                    <th>Sr.No.</th>
                                    <th>District</th>
                                    <th>Dealer Name</th>
                                    {/* <th>Product Quantity</th> */}
                                    {/* <th>Order Received Date</th> */}
                                    <th>Order Amount (Rs)</th>
                                    <th>Officer Name</th>
                                    <th>Reject Remark</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ExcelData && ExcelData.table && ExcelData.table.length > 0 ? ExcelData.table.map((item, i) => (
                                        <tr key={i}>
                                            <td style={{ textAlign: 'center' }} className='w-5'>{item.rowNum}</td>
                                            <td>{item.districtName ? item.districtName : '-'}</td>
                                            <td>{item.dealerName ? item.dealerName : '-'}</td>
                                            {/* <td style={{ textAlign: 'center' }}>
                                                <span
                                                    title='Click'
                                                    className="btn btn-outline-primary text-white mr-2 mt-md-0 mt-lg-1 mx-2 waves-effect waves-light"
                                                    onClick={() => productDetailsPopUpClick(item)}
                                                >
                                                    {item.totalOrderQuantity ? item.totalOrderQuantity : '-'}
                                                </span>
                                            </td> */}
                                            <td>{item.orderAmt ? item.orderAmt.toFixed(2) : '-'}</td>
                                            <td>{item.employeeName ? item.employeeName : '-'}</td>
                                            <td>{item.remark ? item.remark : '-'}</td>
                                        </tr>
                                    )) : <tr>No data</tr>
                                }

                            </tbody>
                        </table>
            }          
        </div>
    )
}