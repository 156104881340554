
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from "moment";

export const InvoiceCreatedExportData = (props) => {
    const { ExcelData, name, RoleID,searchName } = props
    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-FarmerMasterExport"
                className="download-table-xls-button float-end btn btn-export btn-md pt-1 pb-1 pl-3 pr-3 mx-3"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-FarmerMasterExport" style={{ display: 'none', width: '100%' }}>

                <h5><span>&emsp;</span></h5>

                <h4 style={{ textAlign: 'center', fontSize: 16 }}>{name}</h4>

                <tr><td></td></tr>

                <thead className="thead-dark" >
                    <tr>
                        <th style={{ textAlign: "center", width: "5%" }}>Sr.No.</th>
                        {
                            RoleID == 7 || RoleID == 8 ?
                                <></>
                                :
                                <th>State</th>
                        }
                        <th>Season</th>
                        <th>District</th>
                        {
                            searchName === "Invoice Generated" ?
                                <>
                                    <th>Invoice No</th>
                                    <th>InVoice Date</th>
                                    <th>Order Date
                                        < hr style={{ color: "#ffff" }} />
                                        Order No</th>
                                </>
                                :
                                <>
                                    <th>Order Date</th>
                                    <th>Order No</th>
                                </>
                        }
                        <th>Dealer Name</th>                      
                        <th>Order Amount (Rs)</th>
                        <th>Officer Name</th>
                       

                    </tr>
                </thead>
                <tbody>
                    {
                        ExcelData && ExcelData.table && ExcelData.table.length > 0 ? ExcelData.table.map((item, i) => (
                            <tr style={{ textAlign: "center" }}>
                                <td style={{ textAlign: 'center' }} className='w-5'>{item.rowNum}</td>
                                {
                                    RoleID == 7 || RoleID == 8 ?
                                        <></>
                                        :
                                        <td>{item.stateName ? item.stateName : '-'}</td>
                                }
                                <td>{item.seasonName ? item.seasonName : '-'}</td>
                                <td>{item.districtName ? item.districtName : '-'}</td>
                                {
                                    searchName === "Invoice Generated" ?
                                        <>
                                            <td>{item.invoiceNumber ? item.invoiceNumber : '-'}</td>
                                            <td>{item.invoiceDate ? moment(item.invoiceDate).format("DD-MM-YYYY") : '-'}</td>
                                            <td>
                                                {item.orderDate ? moment(item.orderDate).format("DD-MM-YYYY") : '-'}
                                                < hr />
                                                {item.orderNumber ? item.orderNumber : '-'}
                                            </td>
                                        </>
                                        :
                                        <>
                                            <td>{item.orderDate ? moment(item.orderDate).format("DD-MM-YYYY") : '-'}</td>
                                            <td>{item.orderNumber ? item.orderNumber : '-'}</td>
                                        </>
                                }

                                <td>{item.dealerName ? item.dealerName : '-'}</td>                              
                                <td>{item.orderAmt ? item.orderAmt.toFixed(2) : '-'}</td>
                                <td>{item.employeeName ? item.employeeName : '-'}</td>
                                

                            </tr>
                        )) : <tr>No data</tr>
                    }
                </tbody>
            </table>
        </div>
    )
}