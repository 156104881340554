import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Select from 'react-select'
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Header from '../../../../Components/Header/Header'
import Sidebar from '../../../../Components/Sidebar/Sidebar'
import { useAuthState } from '../../../../Helper/Context'
import { DealerNameDDLAPI, DeptDDLAPI, DeptDDLReportAPI, DesignationDDLReportAPI, DistrictNameDDLAPI, EmployeeDDLAPI, EmployeeDDLReportAPI, MonthDDLAPI, ReceivedDealerNameDDLAPI, SeasonDDLAPI, StatusDDLAPI, TalukaNameDDLAPI } from '../../../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { RMDepartmentDDL } from '../../../../Components/CommonDDL/RMDepartmentDDL';
import { RMDesignationDDL } from '../../../../Components/CommonDDL/RMDesignationDDL';
import { GetEmployeeWiseAttendanceExportReportAPI, GetEmployeeWiseAttendanceReportAPI } from '../../../../Redux/ReportSlice/EmployeeWiseAttendanceSlice';
import { ExportEmployeeWiseAttenanceExcel } from './ExportEmployeeWiseAttenanceExcel';
import { Loading } from '../../../../Helper/Loading';

export default function EmployeeWiseAttendance() {
    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let ScreenName = searchParams.get("ScreenName")
    let Flag = searchParams.get("Flag")
    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [Remark, setRemark] = useState("")
    const [Date, setDate] = useState('')
    const [YearValue, setYearValue] = useState(0)
    const [MonthValue, setMonthValue] = useState(0)
    const [MonthName, setMonthName] = useState(moment().format('MMMM'))
    const [DaysInMonth, setDaysInMonth] = useState()

    const [PopUpField, setPopUpField] = React.useState({
        addPopUp: false,
        deletePopUp: false,
        popupFlag: '',
        popupBtn: "",
        apiFlag: "",
        rowData: '',
        rejectPopUp: '',
        approvedRejFlag: '',
        Remark: '',
        ApprovedRejectPopUp: '',

    })

    const [DeptDDL, setDeptDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [DesigDDL, setDesigDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    });

    const [EmpDDL, setEmpDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })

    const handleSearch = () => {
        setIsSearch(!IsSearch)
        setCurrentPage(0)
    }

    const handleClear = () => {
        setIsClear(!IsClear)
        setDate('')
        setDeptDDL({
            ...DeptDDL,
            ID: 0,
            Label: "Select...",
        })
        setDesigDDL({
            ...DesigDDL,
            ID: 0,
            Label: "Select...",
        })
        setEmpDDL({
            ...EmpDDL,
            ID: 0,
            Label: "Select...",
        })

    }

    // ----------Department DDL--------
    useEffect(() => {
        const data = {
            UserID: UserID,
            Flag: 'Employee_Attendance',
            Top: '99999',
            token: token
        }
        dispatch(DeptDDLReportAPI({ data }))
    }, [])

    const { DeptDDLDataa } = useSelector(state => state.DeptDDLReportData)

    useEffect(() => {
        const data = {
            UserID: UserID,
            Flag: 'Employee_Attendance',
            Top: '99999',
            token: token
        };
        dispatch(DesignationDDLReportAPI({ data }));
    }, []);

    const { DesigDDLData } = useSelector((state) => state.DesignationDDLReportData);

    useEffect(() => {
        const data = {
            UserID: UserID,
            token: token,
            DesigDDL: DesigDDL.ID,
            Flag: 'Employee_Attendance',
            Top: '9999'
        }
        dispatch(EmployeeDDLReportAPI({ data }))
    }, [DesigDDL.ID])

    const { EmployeeDDLData } = useSelector(state => state.EmployeeDDLReportData)

    useEffect(() => {
        handleEmployeeDDL()
    }, [EmployeeDDLData])

    const handleEmployeeDDL = () => {
        if (EmployeeDDLData && EmployeeDDLData.table && EmployeeDDLData.table.length > 0) {
            let list = EmployeeDDLData.table.map((item, index) => ({
                value: item.m_EmployeeID,
                label: item.employeeName,
            }))

            setEmpDDL({
                DDL: list,
                ID: 0,
                Label: "Select...",
            })
        }
        else {
            setEmpDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_EmployeeID: EmpDDL.ID,
            M_MonthID: MonthValue !== 0 ? MonthValue : moment().format('M'),
            M_DepartmentID: DeptDDL.ID,
            M_DesignationID: DesigDDL.ID,
            M_UsersID: UserID,
            Top: '99999',
            token: token,
            // handleGetUrl: handleGetUrl
        }
        { YearValue && YearValue !== '0' && dispatch(GetEmployeeWiseAttendanceReportAPI({ data })) }
    }, [MonthValue, YearValue, DeptDDL.ID, DesigDDL.ID, EmpDDL.ID])

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_EmployeeID: EmpDDL.ID,
            M_MonthID: MonthValue !== 0 ? MonthValue : moment().format('M'),
            M_DepartmentID: DeptDDL.ID,
            M_DesignationID: DesigDDL.ID,
            M_UsersID: UserID,
            Top: '99999',
            token: token,
            // handleGetUrl: handleGetUrl
        }
        { YearValue && YearValue !== '0' && dispatch(GetEmployeeWiseAttendanceExportReportAPI({ data })) }
    }, [MonthValue, YearValue, DeptDDL.ID, DesigDDL.ID, EmpDDL.ID])

    const { tableData, isLoading } = useSelector(state => state.GetEmployeeWiseAttendanceReportData)
    const { EmpWiseAttendanceTableData, isExportLoading } = useSelector(state => state.GetEmployeeWiseAttendanceExportReportData)

    useEffect(() => {
        var result
        // result = moment("2012-02", "YYYY-MM").daysInMonth()
        if ((YearValue && YearValue !== '0') && (MonthValue && MonthValue !== '0')) {
            result = moment(YearValue + '-' + MonthValue, "YYYY-MM").daysInMonth()
            setDaysInMonth(result)
            setMonthName(moment(MonthValue, 'M').format('MMMM'))
            // console.log(result)

        } else if (YearValue && YearValue !== '0') {
            result = moment(YearValue + '-' + moment().month(), "YYYY-MM").daysInMonth()
            setDaysInMonth(result)
            // console.log(result)
        }

    }, [YearValue, MonthValue])

    
    return (
        <>
            {isLoading && <Loading />}
            <main className="main-content position-relative border-radius-lg ">
                <Header setYearValue={setYearValue} setMonthValue={setMonthValue} />
                <Sidebar active={ScreenName} listActive="report" reportsActive="employeeWiseattendance" />
                <div id="wrapper">
                    <div id="page-content-wrapper">
                        <div className="container-fluid xyz">
                            <div className="row mt_40" >
                                <div className="row mtop">
                                    <div className="col-md-12 grid-margin">
                                        <div className="row page-heading">
                                            <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-center">
                                                <h4 className="fontStyle">{ScreenName} Attendance</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 mb-xl-0 align-self-center align-items-center">
                                                <h4 className="fontStyle">Month Name : <span style={{ color: 'red' }}>&nbsp;{MonthName} {`(${YearValue})`}</span></h4>
                                            </div>
                                            <div className="col-12 col-lg-4 mb-xl-0">
                                            {
                                                    isExportLoading ?
                                                        <button className="download-table-xls-button float-end btn btn-export btn-md pt-1 pb-1 pl-3 pr-3 mx-3"
                                                            disabled>
                                                            <i
                                                                className="fa fa-refresh fa-spin"
                                                                style={{ marginRight: "5px" }}
                                                            />Loading</button>
                                                        :
                                                        EmpWiseAttendanceTableData && EmpWiseAttendanceTableData.table && EmpWiseAttendanceTableData.table.length > 0 &&
                                                        <ExportEmployeeWiseAttenanceExcel
                                                            ExcelData={EmpWiseAttendanceTableData}
                                                            name='Employee Wise Attendance'
                                                        
                                                        />
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 shadow table-card mt-1 mx-2">
                                        <div className="filter mb-2 mt-2">
                                            <div className="card-body">
                                                <div className='filter-bg p-2'>
                                                    <div className="row">
                                                        <div className="col-md-6 col-lg-3">
                                                            <RMDepartmentDDL
                                                                DeptDDL={DeptDDL}
                                                                setDeptDDL={setDeptDDL}
                                                                DeptDDLDataa={DeptDDLDataa}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 col-lg-3">
                                                            <RMDesignationDDL
                                                                DesigDDL={DesigDDL}
                                                                setDesigDDL={setDesigDDL}
                                                                DesigDDLData={DesigDDLData}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 col-lg-3">
                                                            <div className="form-group">
                                                                <label className="d-block" htmlFor="NameofDepartment">Employee Name</label>
                                                                <Select
                                                                    // isClearable
                                                                    // isRtl={isRtl}
                                                                    isSearchable
                                                                    maxMenuHeight={150}
                                                                    value={{ value: EmpDDL.ID, label: EmpDDL.Label }}
                                                                    onChange={(e) => {
                                                                        e ?
                                                                            setEmpDDL({ ...EmpDDL, ID: e.value, Label: e.label })
                                                                            :
                                                                            setEmpDDL({ ...EmpDDL, ID: 0, Label: "Select..." })

                                                                    }}
                                                                    options={EmpDDL.DDL}
                                                                />
                                                            </div>
                                                        </div>

                                                        {/* <div className="col-md-6 col-lg-2">
                                                            <div className="form-group">
                                                                <label className="d-block" htmlFor="NameofDepartment">Year</label>
                                                                <Select
                                                                    // isClearable
                                                                    // isRtl={isRtl}
                                                                    isSearchable
                                                                    maxMenuHeight={150}
                                                                // value={{ value: MonthDDL.ID, label: MonthDDL.Label }}
                                                                // onChange={(e) => {
                                                                //     e ?
                                                                //         setMonthDDL({ ...MonthDDL, ID: e.value, Label: e.label })
                                                                //         :
                                                                //         setMonthDDL({ ...MonthDDL, ID: 0, Label: "Select..." })

                                                                // }}
                                                                // options={MonthDDL.DDL}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-2">
                                                            <div className="form-group">
                                                                <label className="d-block" htmlFor="NameofDepartment">Month</label>
                                                                <Select
                                                                    // isClearable
                                                                    // isRtl={isRtl}
                                                                    isSearchable
                                                                    maxMenuHeight={150}
                                                                // value={{ value: MonthDDL.ID, label: MonthDDL.Label }}
                                                                // onChange={(e) => {
                                                                //     e ?
                                                                //         setMonthDDL({ ...MonthDDL, ID: e.value, Label: e.label })
                                                                //         :
                                                                //         setMonthDDL({ ...MonthDDL, ID: 0, Label: "Select..." })

                                                                // }}
                                                                // options={MonthDDL.DDL}
                                                                />
                                                            </div>
                                                        </div> */}

                                                        <div className="col-12 col-lg-1 clear">
                                                            {/* <button type="button" className="btn addBtn text-white mr-2 mt-4 mt-md-0 mt-lg-4 mx-2 waves-effect waves-light allBtn"
                                                                onClick={() => handleSearch()}
                                                            >
                                                                Search
                                                            </button> */}
                                                            <button type="button" className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light allBtn"
                                                                onClick={() => handleClear()}
                                                            >
                                                                Clear
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive ">
                                            <div id='table-scroll'>                                             
                                                <table id='expenses' cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Sr.No.</th>
                                                            <th>Department</th>
                                                            <th>Designation</th>
                                                            <th>Employee Name</th>
                                                            <th>Opening Leave <br/>Balance</th>
                                                            <th>Present  <br/> Days</th>
                                                            <th>Absent <br/> Days</th>
                                                            <th>Closing Leave <br/> Balance</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ?
                                                                tableData.table.map((i, j) => {
                                                                    return (
                                                                        <tr>
                                                                            <td style={{ width: '8%' }}>{j + 1}</td>
                                                                            <td>{i?.departmentName}</td>
                                                                            <td>{i?.designationName}</td>
                                                                            <td>{i?.employeeName}</td>
                                                                            <td>{i?.openingLeaveBalance}</td>
                                                                            <td>{i?.totalP_Day}</td>
                                                                            <td>{i?.totalA_Day}</td>
                                                                            <td>{i?.closingLeaveBalance}</td>

                                                                            {/* <td style={{ textAlign: 'center' }} onClick={() => handleGetReport(i.m_UserID, i.id)}>
                                                                            <i class="fa-solid fa-file-pdf p-1" title='Report' style={{ fontSize: '1.1rem', color: 'red', cursor: 'pointer' }}
                                                                            ></i>
                                                                        </td> */}

                                                                        </tr>
                                                                    )
                                                                })
                                                                : "No Data Found"
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {/* {tableData && tableData.table && tableData.table.length > 0 &&
                                            <Pegination
                                                PerPageCount={PerPageCount}
                                                TotalCount={tableData.table[0].totalCount}
                                                setFrom={setFrom}
                                                setTo={setTo}
                                                setrowNo={setrowNo}
                                                CurrentPage={CurrentPage}
                                                setCurrentPage={setCurrentPage}
                                            />
                                        } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main >

        </>
    )
}


