import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";
import { BaseUrl } from "../../Helper/BaseUrl";

// Get Table Data
export const GetActivityAchivementReportAPI = createAsyncThunk("GetActivityAchivementReport", async ({ data }) => {
    const {
        M_MonthID,
        M_UsersID,
        M_FinancialYearID,
        From_M_MonthID,
        To_MonthID,
        M_SeasonID,
        M_DesignationID,
        M_EmployeeID,
        token,
        Top,
        Flag
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DB/Get_RM_ActivityAchivement_Select?M_MonthID=${M_MonthID}&M_FinancialYearID=${M_FinancialYearID}&From_M_MonthID=${From_M_MonthID}&To_MonthID=${To_MonthID}&M_SeasonID=${M_SeasonID}&M_DesignationID=${M_DesignationID}&M_EmployeeID=${M_EmployeeID}&M_UsersID=${M_UsersID}&Flag=${Flag}&Top=${Top}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                return result.data
            } else {
                return result
            }
        })
})

const GetActivityAchivementReportSlice = createSlice({
    name: "GetActivityAchivementReport",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GetActivityAchivementReportAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(GetActivityAchivementReportAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(GetActivityAchivementReportAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const GetActivityAchivementReportReducer = GetActivityAchivementReportSlice.reducer

// Get Report Table Data
export const GetActivityAchivementPDFReportAPI = createAsyncThunk("GetActivityAchivementPDFReport", async ({ data }) => {
    const {
        M_MonthID,
        M_UsersID,
        M_FinancialYearID,
        From_M_MonthID,
        To_MonthID,
        M_SeasonID,
        M_DesignationID,
        M_EmployeeID,
        token,
        Top,
        Flag,
        GetReport
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/DB/Get_RM_ActivityAchivementDetails_Select?M_MonthID=${M_MonthID}&M_UsersID=${M_UsersID}&M_FinancialYearID=${M_FinancialYearID}&From_M_MonthID=${From_M_MonthID}&To_MonthID=${To_MonthID}&M_SeasonID=${M_SeasonID}&M_EmployeeID=${M_EmployeeID}&Flag=${Flag}&Top=${Top}`,requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if(result && result.message == "No data found"){
                toastErrorr(result.message)
            }
            if (result.code >= 200 && result.code <= 300 && result.url) {
                GetReport(result.url)
                return result.url
            } else {
                return result
            }
        })
})

const GetActivityAchivementPDFReportSlice = createSlice({
    name: "GetActivityAchivementPDFReport",
    initialState: {
        isReportLoading: false,
        ReportData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GetActivityAchivementPDFReportAPI.pending, (state, action) => {
            state.isReportLoading = true;
        });
        builder.addCase(GetActivityAchivementPDFReportAPI.fulfilled, (state, action) => {
            state.isReportLoading = false;
            state.ReportData = action.payload;
        });
        builder.addCase(GetActivityAchivementPDFReportAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isReportLoading = false;
            state.isError = true;
            state.ReportData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const GetActivityAchivementPDFReportReducer = GetActivityAchivementPDFReportSlice.reducer

// Get Activity Report Table Data
export const GetActivityPDFReportAPI = createAsyncThunk("GetActivityPDFReport", async ({ data }) => {
    const {
        M_MonthID,
        M_UsersID,
        M_FinancialYearID,
        From_M_MonthID,
        To_MonthID,
        M_SeasonID,
        M_DesignationID,
        M_EmployeeID,
        token,
        Top,
        Flag,
        GetReport
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/DB/Get_RM_ActivityAchivementEmpWise_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&From_M_MonthID=${From_M_MonthID}&To_MonthID=${To_MonthID}&M_SeasonID=${M_SeasonID}&M_DesignationID=${M_DesignationID}&M_EmployeeID=${M_EmployeeID}&M_UsersID=${M_UsersID}&Flag=${Flag}&Top=${Top}`,requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if(result && result.message == "No data found"){
                toastErrorr(result.message)
            }
            if (result.code >= 200 && result.code <= 300 && result.url) {
                GetReport(result.url)
                return result.url
            } else {
                return result
            }
        })
})

const GetActivityPDFReportSlice = createSlice({
    name: "GetActivityPDFReport",
    initialState: {
        isActivityReportLoading: false,
        ActivityReportData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GetActivityPDFReportAPI.pending, (state, action) => {
            state.isActivityReportLoading = true;
        });
        builder.addCase(GetActivityPDFReportAPI.fulfilled, (state, action) => {
            state.isActivityReportLoading = false;
            state.ActivityReportData = action.payload;
        });
        builder.addCase(GetActivityPDFReportAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isActivityReportLoading = false;
            state.isError = true;
            state.ActivityReportData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const GetActivityPDFReportReducer = GetActivityPDFReportSlice.reducer


// Get Month Wise Attendance Table Data
export const GetMonthWiseAttendanceReportAPI = createAsyncThunk("GetMonthWiseAttendanceReport", async ({ data }) => {
    const {
        M_MonthID,
        M_UsersID,
        M_FinancialYearID,
        M_DepartmentID,
        M_DesignationID,
        M_EmployeeID,
        token,
        Top,
        handleGetUrl
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DB/Get_RM_M_Employee_Attendance_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_DepartmentID=${M_DepartmentID}&M_DesignationID=${M_DesignationID}&M_EmployeeID=${M_EmployeeID}&M_UsersID=${M_UsersID}&Top=${Top}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                handleGetUrl(result.url)
                return result.data
            } else {
                return result
            }
        })
})

const GetMonthWiseAttendanceReportSlice = createSlice({
    name: "GetMonthWiseAttendanceReport",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GetMonthWiseAttendanceReportAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(GetMonthWiseAttendanceReportAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(GetMonthWiseAttendanceReportAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const GetMonthWiseAttendanceReportReducer = GetMonthWiseAttendanceReportSlice.reducer