import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Select from 'react-select'
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";
import Header from '../../../../Components/Header/Header'
import Sidebar from '../../../../Components/Sidebar/Sidebar'
import { useAuthState } from '../../../../Helper/Context'
import { DealerNameDDLAPI, DeptDDLAPI, DistrictNameDDLAPI, DistrictOfficerDDLReportAPI, EmployeeDDLAPI, MonthDDLAPI, ProductNameDDLReportAPI, ReceivedDealerNameDDLAPI, SeasonDDLAPI, SeasonWiseMonthDDLAPI, StatusDDLAPI, TalukaNameDDLAPI } from '../../../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { SeasonDataDDL } from '../../../../Components/CommonDDL/SeasonDataDDL'
import { DistrictNameDataDDL } from '../../../../Components/CommonDDL/DistrictNameDataDDL'
import { ApprovalOrderApprovedRejectAPI, ApprovalOrderExportTableDataAPI, ApprovalOrderTableDataAPI } from '../../../../Redux/StateInChargeSlice/ApprovalOrderSlice'
import { Pegination } from '../../../../Components/Pegination/Pegination';
import { Loading } from '../../../../Helper/Loading';
import { AstricSign } from '../../../../Helper/AstricSign';
import { ReceivedDealearDDL } from '../../../../Components/CommonDDL/ReceivedDealearDDL';
import { SeasonWiseMonthDataDDL } from '../../../../Components/CommonDDL/SeasonWiseMonthDataDDL';
import { GetTargetAchievementReportAPI, GetTargetAchivementPDFReportAPI } from '../../../../Redux/ReportSlice/TargetAchievementReportSlice/TargetAchievementReportSlice';
import { toastErrorr } from '../../../../Helper/ToastMessage';
import { ExportTargetAndAchievement } from './ExportTargetAndAchievement';
import { RMDistrictOfficerDDL } from '../../../../Components/CommonDDL/RMDistrictOfficerDDL';
import { RMProductNameDDL } from '../../../../Components/CommonDDL/RMProductNameDDL';

export default function TargetAndAchievement() {
    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    const ScreenName = searchParams.get("ScreenName")
    const Flag = searchParams.get("Flag")

    const [YearValue, setYearValue] = useState(0)
    const [MonthValue, setMonthValue] = useState(0)


    const [SeasonDDL, setSeasonDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select..."
    })
    const [MonthDDL, setMonthDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [DistrictOfficerDDL, setDistrictOfficerDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [ProductNameDDL, setProductNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const handleClear = () => {
        setMonthDDL({
            ...MonthDDL,
            ID: 0,
            Label: "Select...",
        })
        setSeasonDDL({
            ...SeasonDDL,
            ID: 0,
            Label: "Select...",
        })
        setDistrictOfficerDDL({
            ...DistrictOfficerDDL,
            ID: 0,
            Label: "Select...",
        })
        setProductNameDDL({
            ...ProductNameDDL,
            ID: 0,
            Label: "Select...",
        })
    }

    useEffect(() => {
        const data = { UserID, token }
        dispatch(SeasonDDLAPI({ data }))
    }, [])

    const { SeasonDDLData } = useSelector(state => state.SeasonDDLData)

    // ----Month DDL -------

    useEffect(() => {
        const data = { UserID, token, SeasonDDL }
        dispatch(SeasonWiseMonthDDLAPI({ data }))
    }, [SeasonDDL.ID])

    const { SeasonWiseMonthData } = useSelector(state => state.SeasonWiseMonthDDL)


    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_MonthID: MonthDDL.ID,
            M_SeasonID: SeasonDDL.ID,
            M_DesignationID: '0',
            M_UsersID: UserID,
            token: token,
            Flag: Flag
        }
        { YearValue && YearValue !== 0 && dispatch(DistrictOfficerDDLReportAPI({ data })) }
    }, [YearValue, MonthDDL.ID, SeasonDDL.ID, Flag])

    const { DistrictOfficerDDLData } = useSelector(state => state.DistrictOfficerDDLReportData)


    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_MonthID: MonthDDL.ID,
            M_SeasonID: SeasonDDL.ID,
            M_DesignationID: '0',
            M_UsersID: UserID,
            M_EmployeeID: '0',
            token: token,
            Flag: Flag
        }
        { YearValue && YearValue !== 0 && dispatch(ProductNameDDLReportAPI({ data })) }
    }, [YearValue, MonthDDL.ID, SeasonDDL.ID, Flag])

    const { ProductNameDDLData } = useSelector(state => state.ProductNameDDLReportData)

    const GetReport = (PdfUrl) => {
        if (PdfUrl) {
            window.open(PdfUrl)
        } else {
            toastErrorr('Report not found')
        }
    }

    useEffect(() => {
        const data = {
            M_MonthID: MonthDDL.ID,
            M_UsersID: UserID,
            M_FinancialYearID: YearValue,
            M_ProductID: ProductNameDDL.ID,
            M_SeasonID: SeasonDDL.ID,
            M_DesignationID: '0',
            M_EmployeeID: DistrictOfficerDDL.ID,
            token: token,
            Top: '99999',
            // Flag:  ScreenName === "Order Target" ? 'T_OrderPunch' : 'PaymentTarget',
            Flag: Flag,
            ScreenName: ScreenName
        }
        { YearValue && YearValue !== '0' && dispatch(GetTargetAchievementReportAPI({ data })) }
    }, [YearValue, MonthDDL.ID, SeasonDDL.ID, Flag, ProductNameDDL.ID])

    const { tableData, isLoading } = useSelector(state => state.GetTargetAchievementReportData)


    const handleGetReport = (M_UsersID, m_DesignationID, m_ProductID, m_EmployeeID) => {
        const data = {
            M_MonthID: MonthValue,
            M_UsersID: UserID,
            M_FinancialYearID: YearValue,
            M_ProductID: m_ProductID,
            M_SeasonID: SeasonDDL.ID,
            M_DesignationID: m_DesignationID,
            M_EmployeeID: m_EmployeeID,
            token: token,
            Top: '99999',
            Flag: Flag,
            GetReport: GetReport,
            ScreenName: ScreenName
        }
        dispatch(GetTargetAchivementPDFReportAPI({ data }))
    }

    const { isReportLoading } = useSelector(state => state.GetTargetAchivementPDFReportData)

    return (
        <>
            {isLoading && <Loading />}
            {isReportLoading && <Loading />}
            <main className="main-content position-relative border-radius-lg ">
                <Header setYearValue={setYearValue} setMonthValue={setMonthValue} />
                <Sidebar active={ScreenName} listActive="report" reportsActive="targetachievement" />
                <div id="wrapper">
                    <div id="page-content-wrapper">
                        <div className="container-fluid xyz">
                            <div className="row mt_40" >
                                <div className="row mtop">
                                    <div className="col-md-12 grid-margin">
                                        <div className="row page-heading">
                                            <div className="col-12 col-lg-8 mb-xl-0 align-self-center align-items-center">
                                                <h4 className="fontStyle">{ScreenName} Achievement</h4>
                                            </div>
                                            <div className="col-12 col-lg-4 mb-xl-0">
                                                {
                                                    isLoading ?
                                                        <button className="download-table-xls-button float-end btn btn-export btn-md pt-1 pb-1 pl-3 pr-3 mx-3"
                                                            disabled>
                                                            <i
                                                                className="fa fa-refresh fa-spin"
                                                                style={{ marginRight: "5px" }}
                                                            />Loading</button>
                                                        :
                                                        tableData && tableData.table && tableData.table.length > 0 &&
                                                        <ExportTargetAndAchievement
                                                            ExcelData={tableData}
                                                            name={ScreenName == 'Order Target' ? 'Order Target Activity' : 'Financial Target Activity'}
                                                            ScreenName={ScreenName}
                                                        />
                                                }

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 shadow table-card mt-1 mx-2">
                                        <div className="filter mb-2 mt-2">
                                            <div className="card-body">
                                                <div className='filter-bg p-2'>
                                                    <div className="row">
                                                        <div className="col-md-6 col-lg-2">
                                                            <div className="form-group">
                                                                <SeasonDataDDL
                                                                    SeasonDDL={SeasonDDL}
                                                                    setSeasonDDL={setSeasonDDL}
                                                                    SeasonDDLData={SeasonDDLData}
                                                                    mandatory={false}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-3">
                                                            <SeasonWiseMonthDataDDL
                                                                MonthDDL={MonthDDL}
                                                                setMonthDDL={setMonthDDL}
                                                                SeasonWiseMonthData={SeasonWiseMonthData}
                                                                mandatory={false}
                                                                name="Month"
                                                            />
                                                        </div>
                                                        <div className="col-md-6 col-lg-3">
                                                            <RMDistrictOfficerDDL
                                                                DistrictOfficerDDL={DistrictOfficerDDL}
                                                                setDistrictOfficerDDL={setDistrictOfficerDDL}
                                                                DistrictOfficerDDLData={DistrictOfficerDDLData}
                                                                mandatory={false}
                                                            />
                                                        </div>
                                                        {
                                                            ScreenName === "Order Target" ?
                                                                <></>
                                                                :
                                                                <div className="col-md-6 col-lg-3">
                                                                    <RMProductNameDDL
                                                                        ProductNameDDL={ProductNameDDL}
                                                                        setProductNameDDL={setProductNameDDL}
                                                                        ProductNameDDLData={ProductNameDDLData}
                                                                        mandatory={false}
                                                                    />
                                                                </div>
                                                        }


                                                        <div className="col-12 col-lg-1 clear">
                                                            {/* <button type="button" className="btn addBtn text-white mr-2 mt-4 mt-md-0 mt-lg-4 mx-2 waves-effect waves-light allBtn"
                                                                onClick={() => handleSearch()}
                                                            >
                                                                Search
                                                            </button> */}
                                                            <button type="button" className="btn btn-clear text-white mr-2 mt-4 mt-md-0 mt-lg-4 waves-effect waves-light allBtn"
                                                                onClick={() => handleClear()}
                                                            >
                                                                Clear
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive ">
                                            <table id='expenses' cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Sr.No.</th>
                                                        <th>Employee Name</th>
                                                        <th>District</th>
                                                        {
                                                            ScreenName === "Order Target" ?
                                                                <>
                                                                    <th>Date</th>
                                                                </>
                                                                :
                                                                ''
                                                        }
                                                        <th>Product Name</th>
                                                        {
                                                            ScreenName === "Order Target" ?
                                                                <>
                                                                    <th>Pack Size</th>
                                                                </>
                                                                :
                                                                ''
                                                        }

                                                        {
                                                            ScreenName === "Order Target" ?
                                                                <>
                                                                    <th>Target Quantity</th>
                                                                    <th>Achieved Quantity</th>
                                                                    <th>Extra Achieved Quantity</th>
                                                                </>
                                                                :
                                                                <>
                                                                    <th> Financial Target</th>
                                                                    <th> FinancialAchieved</th>
                                                                </>
                                                        }

                                                        <th>Report</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        tableData && tableData.table && tableData.table.length > 0 ?
                                                            tableData.table.map((i, j) => {
                                                                return (
                                                                    <tr>
                                                                        <td style={{ textAlign: 'center' }}>{j + 1}</td>
                                                                        <td>{i?.employeeName}</td>
                                                                        <td>{i?.districtName}</td>
                                                                        {
                                                                            ScreenName === "Order Target" ?
                                                                                <>
                                                                                    <td>{'-'}</td>
                                                                                </>
                                                                                :
                                                                                ''
                                                                        }
                                                                        <td>{i?.product_Name}</td>
                                                                        {
                                                                            ScreenName === "Order Target" ?
                                                                                <>
                                                                                    <td>{'-'}</td>
                                                                                </>
                                                                                :
                                                                                ''
                                                                        }
                                                                        {
                                                                            ScreenName === "Order Target" ?
                                                                                <>
                                                                                    <td>{i?.targetCount}</td>
                                                                                    <td>{i.achievedCount ? i.achievedCount : '-'}</td>
                                                                                    <td>{'-'}</td>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <td>{i?.targetCount}</td>
                                                                                    <td>{i.achievedCount ? i.achievedCount : '-'}</td>
                                                                                </>
                                                                        }

                                                                        <td style={{ textAlign: 'center' }} >
                                                                            {i.achievedCount ?
                                                                                <i className="fa-solid fa-file-pdf p-1" title='Report' style={{ fontSize: '1.1rem', color: 'red', cursor: 'pointer' }}
                                                                                    onClick={() => handleGetReport(i.m_UserID, i.m_DesignationID, i.m_ProductID, i.m_EmployeeID)}
                                                                                ></i>
                                                                                : '-'
                                                                            }

                                                                        </td>
                                                                    </tr>

                                                                )
                                                            })
                                                            : 'No data found'
                                                    }


                                                </tbody>
                                            </table>

                                        </div>
                                        {/* {tableData && tableData.table && tableData.table.length > 0 &&
                                            <Pegination
                                                PerPageCount={PerPageCount}
                                                TotalCount={tableData.table[0].totalCount}
                                                setFrom={setFrom}
                                                setTo={setTo}
                                                setrowNo={setrowNo}
                                                CurrentPage={CurrentPage}
                                                setCurrentPage={setCurrentPage}
                                            />
                                        } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main >

        </>
    )
}


