
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr } from "../../../Helper/ToastMessage";


// Get Table Data
export const GetTargetAchievementReportAPI = createAsyncThunk("GetTargetAchievementReport", async ({ data }) => {
    const {
        M_MonthID,
        M_UsersID,
        M_FinancialYearID,
        M_ProductID,
        M_SeasonID,
        M_DesignationID,
        M_EmployeeID,
        token,
        Top,
        Flag,
        ScreenName
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    
    let url
    if(ScreenName === "Order Target"){
        url = `${BaseUrl}/DB/Get_RM_T_OrderTargetAchievement_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_SeasonID=${M_SeasonID}&M_DesignationID=${M_DesignationID}&M_EmployeeID=${M_EmployeeID}&M_UsersID=${M_UsersID}&M_ProductID=${M_ProductID}&Flag=${Flag}&Top=${Top}`
    }else{
        url = `${BaseUrl}/DB/Get_RM_T_PaymentTargetAchievement_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_SeasonID=${M_SeasonID}&M_DesignationID=${M_DesignationID}&M_EmployeeID=${M_EmployeeID}&M_UsersID=${M_UsersID}&M_ProductID=${M_ProductID}&Flag=${Flag}&Top=${Top}`
    }

    return fetch(url, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                return result.data
            } else {
                return result
            }
        })
})

const GetTargetAchievementReportSlice = createSlice({
    name: "GetTargetAchievementReport",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GetTargetAchievementReportAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(GetTargetAchievementReportAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(GetTargetAchievementReportAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const GetTargetAchievementReportReducer = GetTargetAchievementReportSlice.reducer


// Get Report Table Data
export const GetTargetAchivementPDFReportAPI = createAsyncThunk("GetTargetAchivementPDFReport", async ({ data }) => {
    const {
        M_MonthID,
        M_UsersID,
        M_FinancialYearID,
        M_ProductID,
        M_SeasonID,
        M_DesignationID,
        M_EmployeeID,
        token,
        Top,
        Flag,
        GetReport,
        ScreenName
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    let url
    if(ScreenName === "Order Target"){
        url = `${BaseUrl}/DB/Get_RM_T_OrderTargetAchievementDetails_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_SeasonID=${M_SeasonID}&M_DesignationID=${M_DesignationID}&M_EmployeeID=${M_EmployeeID}&M_UsersID=${M_UsersID}&M_ProductID=${M_ProductID}&Flag=${Flag}&Top=${Top}`
    }else{
        url = `${BaseUrl}/DB/Get_RM_T_PaymentTargetAchievementDetails_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_SeasonID=${M_SeasonID}&M_DesignationID=${M_DesignationID}&M_EmployeeID=${M_EmployeeID}&M_UsersID=${M_UsersID}&M_ProductID=${M_ProductID}&Flag=${Flag}&Top=${Top}`
    }
    return fetch(url,requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if(result && result.message == "No data found"){
                toastErrorr(result.message)
            }
            if (result.code >= 200 && result.code <= 300 && result.url) {
                GetReport(result.url)
                return result.url
            } else {
                return result
            }
        })
})

const GetTargetAchivementPDFReportSlice = createSlice({
    name: "GetTargetAchivementPDFReport",
    initialState: {
        isReportLoading: false,
        ReportData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GetTargetAchivementPDFReportAPI.pending, (state, action) => {
            state.isReportLoading = true;
        });
        builder.addCase(GetTargetAchivementPDFReportAPI.fulfilled, (state, action) => {
            state.isReportLoading = false;
            state.ReportData = action.payload;
        });
        builder.addCase(GetTargetAchivementPDFReportAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isReportLoading = false;
            state.isError = true;
            state.ReportData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const GetTargetAchivementPDFReportReducer = GetTargetAchivementPDFReportSlice.reducer

