


import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from 'moment'

export const ExportTargetAndAchievement = (props) => {
    const { ExcelData, name, ScreenName } = props
    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-ExportTargetAndAchievement"
                className="download-table-xls-button float-end btn btn-export btn-md pt-1 pb-1 pl-3 pr-3 mx-3"
                filename={`${name}`}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-ExportTargetAndAchievement" style={{ display: 'none', width: '100%' }}>

                <h5><span>&emsp;</span></h5>

                <h4 style={{ textAlign: 'center', fontSize: 16 }}>{name}</h4>


                <thead className="thead-dark" >
                    <tr style={{ backgroundColor: '#495057', color: "#fff", border: '1px solid' }}>
                        <th>Sr.No.</th>
                        <th>Employee Name</th>
                        <th>District</th>
                        <th>Product Name</th>
                        {/* <th>Pack Size</th> */}
                        {
                            ScreenName === "Order Target" ?
                                <>
                                    <th>Target Quantity</th>
                                    <th>Achieved Quantity</th>
                                </>
                                :
                                <>
                                    <th> Financial Target</th>
                                    <th> FinancialAchieved</th>
                                </>
                        }

                    </tr>
                </thead>
                <tbody>
                    {
                        ExcelData && ExcelData.table && ExcelData.table.length > 0 ? ExcelData.table.map((i, j) => (
                            <tr>

                                <td style={{ textAlign: 'center' }}>{j + 1}</td>
                                <td>{i?.employeeName}</td>
                                <td>{i?.districtName}</td>
                                <td>{i?.product_Name}</td>
                                <td>{i?.targetCount}</td>
                                <td>{i?.achievedCount}</td>

                            </tr>
                        )) : <tr>No data</tr>
                    }
                </tbody>
            </table>
        </div>
    )
}