


import { useEffect } from 'react'
import Select from 'react-select'
import { AstricSign } from '../../Helper/AstricSign'

export const RMDesignationNameDDL = (props) => {
    const { RMDesignationDDL, setRMDesignationDDL, ReportDesignationNameData, mandatory } = props

    useEffect(() => {
        handleOfficerNameDDL()
    }, [ReportDesignationNameData])

    const handleOfficerNameDDL = () => {
        // console.log(DeptDDLDataa)
        if (ReportDesignationNameData && ReportDesignationNameData.table && ReportDesignationNameData.table.length > 0) {
            let list = ReportDesignationNameData.table.map((item, index) => ({
                // value: item.departmentCode,
                value: item.m_DesignationID,
                label: item.designationName,
            }))

            setRMDesignationDDL({
                DDL: list,
                ID:  0 ,
                Label: "Select..."
            })
        }
        else {
            setRMDesignationDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment">Designation {mandatory && <AstricSign />}</label>
            <Select
                // isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: RMDesignationDDL.ID, label: RMDesignationDDL.Label }}
                onChange={(e) => {
                    e ?
                    setRMDesignationDDL({ ...RMDesignationDDL, ID: e.value, Label: e.label })
                        :
                        setRMDesignationDDL({ ...RMDesignationDDL, ID: 0, Label: "Select..." })

                }}
                options={RMDesignationDDL.DDL}
            />
        </div>
    )
}