
import { useEffect } from 'react'
import Select from 'react-select'

export const RMProductNameDDL = (props) => {
    const { ProductNameDDL, setProductNameDDL, ProductNameDDLData} = props


    useEffect(() => {
        handleDDL()
    }, [ProductNameDDLData])

    const handleDDL = () => {
        if (ProductNameDDLData && ProductNameDDLData.table && ProductNameDDLData.table.length > 0) {
            let list = ProductNameDDLData.table.map((item, index) => ({
                value: item.m_ProductID,
                label: item.product_Name,
            }))

            setProductNameDDL({
                DDL: list,
                ID: '0',
                Label:"Select..."
            })
        }
        else {
            setProductNameDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }


    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment">Product Name</label>
            <Select
                // isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: ProductNameDDL.ID, label: ProductNameDDL.Label }}
                onChange={(e) => {
                    e ?
                        setProductNameDDL({ ...ProductNameDDL, ID: e.value, Label: e.label })
                        :
                        setProductNameDDL({ ...ProductNameDDL, ID: 0, Label: "Select..." })

                }}
                options={ProductNameDDL.DDL}
            />
        </div>
    )
}