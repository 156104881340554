
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";
import { BaseUrl } from "../../Helper/BaseUrl";
// Get Employee Wise Attendance Table Data

export const GetEmployeeWiseAttendanceReportAPI = createAsyncThunk("GetEmployeeWiseAttendanceReport", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_EmployeeID,
        M_MonthID,
        M_DepartmentID,
        M_DesignationID,
        M_UsersID,
        token,
        Top,
        handleGetUrl
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DB/Get_RM_M_Employee_AttendanceMonthWise_Select?M_FinancialYearID=${M_FinancialYearID}&M_EmployeeID=${M_EmployeeID ? M_EmployeeID : "0"}&M_MonthID=${M_MonthID ? M_MonthID : '0'}&M_DepartmentID=${M_DepartmentID ? M_DepartmentID : '0'}&M_DesignationID=${M_DesignationID ? M_DesignationID : '0' }&M_UsersID=${M_UsersID}&Top=${Top}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                return result.data
               
            } else {
                return result
            }
        })
})

const GetEmployeeWiseAttendanceReportSlice = createSlice({
    name: "GetEmployeeWiseAttendanceReport",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GetEmployeeWiseAttendanceReportAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(GetEmployeeWiseAttendanceReportAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(GetEmployeeWiseAttendanceReportAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const GetEmployeeWiseAttendanceReportReducer = GetEmployeeWiseAttendanceReportSlice.reducer

export const GetEmployeeWiseAttendanceExportReportAPI = createAsyncThunk("GetEmployeeWiseAttendanceExportReport", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_EmployeeID,
        M_MonthID,
        M_DepartmentID,
        M_DesignationID,
        M_UsersID,
        token,
        Top,
        handleGetUrl
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/DB/Get_RM_M_Employee_AttendanceMonthWise_Select?M_FinancialYearID=${M_FinancialYearID}&M_EmployeeID=${M_EmployeeID ? M_EmployeeID : "0"}&M_MonthID=${M_MonthID ? M_MonthID : '0'}&M_DepartmentID=${M_DepartmentID ? M_DepartmentID : '0'}&M_DesignationID=${M_DesignationID ? M_DesignationID : '0' }&M_UsersID=${M_UsersID}&Top=${Top}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                return result.data
               
            } else {
                return result
            }
        })
})

const GetEmployeeWiseAttendanceExportReportSlice = createSlice({
    name: "GetEmployeeWiseAttendanceExportReport",
    initialState: {
        isExportLoading: false,
        EmpWiseAttendanceTableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GetEmployeeWiseAttendanceExportReportAPI.pending, (state, action) => {
            state.isExportLoading = true;
        });
        builder.addCase(GetEmployeeWiseAttendanceExportReportAPI.fulfilled, (state, action) => {
            state.isExportLoading = false;
            state.EmpWiseAttendanceTableData = action.payload;
        });
        builder.addCase(GetEmployeeWiseAttendanceExportReportAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isExportLoading = false;
            state.isError = true;
            state.EmpWiseAttendanceTableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const GetEmployeeWiseAttendanceExportReportReducer = GetEmployeeWiseAttendanceExportReportSlice.reducer