

import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from 'moment'

export const ExportMonthWiseAttendance = (props) => {
    const { ExcelData, name, DaysInMonth, MonthName, YearValue } = props
    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-ExportMonthWiseAttendance"
                className="download-table-xls-button float-end btn btn-export btn-md pt-1 pb-1 pl-3 pr-3 mx-3"
                filename={`${name} of ${MonthName}-${YearValue}`}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-ExportMonthWiseAttendance" style={{ display: 'none', width: '100%' }}>

                <h5><span>&emsp;</span></h5>

                <h4 style={{ textAlign: 'center', fontSize: 16 }}>{name}</h4>

                <tr>
                    <td colSpan={2}></td>
                    <td style={{fontWeight:'bold'}} colSpan={10}>Year : {YearValue}</td>
                    <td style={{fontWeight:'bold'}} colSpan={10}>Month : {MonthName}</td>
                </tr>

                <thead className="thead-dark" >
                    <tr style={{ backgroundColor: '#495057', color: "#fff", border: '1px solid' }}>
                        <th>Sr.No.</th>
                        <th>Employee Name</th>

                        {Array.from({ length: DaysInMonth }).map((x, i) => (
                            <th>
                                {
                                    moment().startOf('month').add(i, 'days').format('DD')
                                }
                            </th>
                        ))}

                        <th>Total Present Days</th>
                        <th>Total Absent Days</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        ExcelData && ExcelData.table && ExcelData.table.length > 0 ? ExcelData.table.map((item, j) => (
                            <tr>
                                <td style={{ textAlign: 'center' }}>{j + 1}</td>
                                <td>{item?.employeeName}</td>
                                {/* {Array.from({ length: DaysInMonth }).map((x, i) => {
                                                                            var g = 'd'+i+1
                                                                            return (
                                                                                // console.log(item.g)
                                                                                <td>{g ? item.g :"-"}</td>
                                                                            )
                                                                        })} */}
                                {
                                    DaysInMonth == '28' ?
                                        <>
                                            <td>{item?.d1}</td>
                                            <td>{item?.d2}</td>
                                            <td>{item?.d3}</td>
                                            <td>{item?.d4}</td>
                                            <td>{item?.d5}</td>
                                            <td>{item?.d6}</td>
                                            <td>{item?.d7}</td>
                                            <td>{item?.d8}</td>
                                            <td>{item?.d9}</td>
                                            <td>{item?.d10}</td>

                                            <td>{item?.d11}</td>
                                            <td>{item?.d12}</td>
                                            <td>{item?.d13}</td>
                                            <td>{item?.d14}</td>
                                            <td>{item?.d15}</td>
                                            <td>{item?.d16}</td>
                                            <td>{item?.d17}</td>
                                            <td>{item?.d18}</td>
                                            <td>{item?.d19}</td>
                                            <td>{item?.d20}</td>

                                            <td>{item?.d21}</td>
                                            <td>{item?.d22}</td>
                                            <td>{item?.d23}</td>
                                            <td>{item?.d24}</td>
                                            <td>{item?.d25}</td>
                                            <td>{item?.d26}</td>
                                            <td>{item?.d27}</td>
                                            <td>{item?.d28}</td>
                                            {/* <td>{item?.d29}</td>
                                                                        <td>{item?.d30}</td>
                                                                        <td>{item?.d31}</td> */}
                                        </>
                                        :
                                        DaysInMonth == '29' ?
                                            <>
                                                <td>{item?.d1}</td>
                                                <td>{item?.d2}</td>
                                                <td>{item?.d3}</td>
                                                <td>{item?.d4}</td>
                                                <td>{item?.d5}</td>
                                                <td>{item?.d6}</td>
                                                <td>{item?.d7}</td>
                                                <td>{item?.d8}</td>
                                                <td>{item?.d9}</td>
                                                <td>{item?.d10}</td>

                                                <td>{item?.d11}</td>
                                                <td>{item?.d12}</td>
                                                <td>{item?.d13}</td>
                                                <td>{item?.d14}</td>
                                                <td>{item?.d15}</td>
                                                <td>{item?.d16}</td>
                                                <td>{item?.d17}</td>
                                                <td>{item?.d18}</td>
                                                <td>{item?.d19}</td>
                                                <td>{item?.d20}</td>

                                                <td>{item?.d21}</td>
                                                <td>{item?.d22}</td>
                                                <td>{item?.d23}</td>
                                                <td>{item?.d24}</td>
                                                <td>{item?.d25}</td>
                                                <td>{item?.d26}</td>
                                                <td>{item?.d27}</td>
                                                <td>{item?.d28}</td>
                                                <td>{item?.d29}</td>
                                                {/* <td>{item?.d30}</td>
                                                                        <td>{item?.d31}</td> */}
                                            </>
                                            :
                                            DaysInMonth == '30' ?
                                                <>
                                                    <td>{item?.d1}</td>
                                                    <td>{item?.d2}</td>
                                                    <td>{item?.d3}</td>
                                                    <td>{item?.d4}</td>
                                                    <td>{item?.d5}</td>
                                                    <td>{item?.d6}</td>
                                                    <td>{item?.d7}</td>
                                                    <td>{item?.d8}</td>
                                                    <td>{item?.d9}</td>
                                                    <td>{item?.d10}</td>

                                                    <td>{item?.d11}</td>
                                                    <td>{item?.d12}</td>
                                                    <td>{item?.d13}</td>
                                                    <td>{item?.d14}</td>
                                                    <td>{item?.d15}</td>
                                                    <td>{item?.d16}</td>
                                                    <td>{item?.d17}</td>
                                                    <td>{item?.d18}</td>
                                                    <td>{item?.d19}</td>
                                                    <td>{item?.d20}</td>

                                                    <td>{item?.d21}</td>
                                                    <td>{item?.d22}</td>
                                                    <td>{item?.d23}</td>
                                                    <td>{item?.d24}</td>
                                                    <td>{item?.d25}</td>
                                                    <td>{item?.d26}</td>
                                                    <td>{item?.d27}</td>
                                                    <td>{item?.d28}</td>
                                                    <td>{item?.d29}</td>
                                                    <td>{item?.d30}</td>
                                                    {/* <td>{item?.d31}</td> */}
                                                </>
                                                :
                                                <>
                                                    <td>{item?.d1}</td>
                                                    <td>{item?.d2}</td>
                                                    <td>{item?.d3}</td>
                                                    <td>{item?.d4}</td>
                                                    <td>{item?.d5}</td>
                                                    <td>{item?.d6}</td>
                                                    <td>{item?.d7}</td>
                                                    <td>{item?.d8}</td>
                                                    <td>{item?.d9}</td>
                                                    <td>{item?.d10}</td>

                                                    <td>{item?.d11}</td>
                                                    <td>{item?.d12}</td>
                                                    <td>{item?.d13}</td>
                                                    <td>{item?.d14}</td>
                                                    <td>{item?.d15}</td>
                                                    <td>{item?.d16}</td>
                                                    <td>{item?.d17}</td>
                                                    <td>{item?.d18}</td>
                                                    <td>{item?.d19}</td>
                                                    <td>{item?.d20}</td>

                                                    <td>{item?.d21}</td>
                                                    <td>{item?.d22}</td>
                                                    <td>{item?.d23}</td>
                                                    <td>{item?.d24}</td>
                                                    <td>{item?.d25}</td>
                                                    <td>{item?.d26}</td>
                                                    <td>{item?.d27}</td>
                                                    <td>{item?.d28}</td>
                                                    <td>{item?.d29}</td>
                                                    <td>{item?.d30}</td>
                                                    <td>{item?.d31}</td>

                                                </>
                                }

                                <td>{item?.totalP_Day}</td>
                                <td>{item?.totalA_Day}</td>
                            </tr>
                        )) : <tr>No data</tr>
                    }
                </tbody>
            </table>
        </div>
    )
}